import { Injectable } from "@angular/core";
import { AngularFireStorageV2, AngularFirestoreV2 } from "../firebase/firebasev2.module";
import { GlobalService } from "./global.service";
import { BehaviorSubject } from "rxjs";
import { UploadedFile, UploadStatus } from "../models/uploadedfile.model";
import { v4 as uuidv4 } from "uuid";
import { Authv2Service } from "./authv2.service";
import { environment } from "../../environments/environment";

@Injectable()
export class UploadService {
  private _queue: BehaviorSubject<UploadedFile[]>;
  private _files: UploadedFile[] = [];
  private readonly _basePath: string = 'unprocessed'

  constructor(private _storage: AngularFireStorageV2, 
              private _db: AngularFirestoreV2, 
              private _auth: Authv2Service,
              private _globalService: GlobalService) {
    this._files = [];
    this._queue = new BehaviorSubject(this._files);
  }

  uploadStatusCallback(uploadedFile: UploadedFile) {
    if (uploadedFile.isUploaded) {
      this.persistFileToFireStore(uploadedFile);
    } else if (uploadedFile.isCancelled) {
      this.removeFile(uploadedFile);
    }
  }

  persistFileToFireStore(uploadedFile: UploadedFile) {
    const documentPath = `${this._globalService.siteId}/_metadata/uploadQueue/${uploadedFile.fileKey}`;

    this._db.doc(documentPath).set(
      {
        uploadedByEmail: this._auth.user.email,
        displayFilename: uploadedFile.filename,
        filename: uploadedFile.persistedFilename,
        downloadUrl: uploadedFile.downloadUrl,
        gsPath: `gs://${environment.specialOffers.storageBucket}/${uploadedFile.uploadPath}`,
        bucketName: environment.specialOffers.storageBucket,
        uploadPath: uploadedFile.uploadPath,
        uploadedAt: (new Date()).getTime()
      }
    ).then(() => {
      uploadedFile.inspect();

      const dataSubscription = this._db.doc(documentPath).snapshotChanges().subscribe((snapshot) => {
        const data = snapshot.payload.data() as any

        if (data.fileInspection) {
          dataSubscription.unsubscribe();
          uploadedFile.fileInspection = data.fileInspection;
          uploadedFile.done();
        }
      })
    }).catch((reason) => {
      console.log(reason);
    })
  }

  removeFile(uploadedFile: UploadedFile) {
    const index = this._files.findIndex(val => {
      return val.filename === uploadedFile.filename;
    })

    if (index !== -1) {
      this._files.splice(index, 1);
      this._queue.next(this._files);
    }
  }

  fileExistsInQueue(file: File): boolean {
    return this._files.some(uploadFile => {
      return uploadFile.filename === file.name;
    });
  }

  clear() {
    this._files = [];
    this._queue.next([]);
  }

  import(uploadedFile: UploadedFile) {
    uploadedFile.setStatus(UploadStatus.Importing);
    
    const importData = {
      import: true,
      fileInspection: {
        dynamicOfferName: uploadedFile.offerName || ""
      }
    };

    const documentPath = `${this._globalService.siteId}/_metadata/uploadQueue/${uploadedFile.fileKey}`;

    this._db.doc(documentPath).set(
      importData, { merge: true}
    ).then(() => {

    }).catch(reason=> {
      console.log(reason);
    })
  }

  addToQueue(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
    
      if (this.fileExistsInQueue(file))
        continue;

      const filename = file.name;
      const fileKey = uuidv4();
      const persistedFilename = `${fileKey}.csv`;
      const filesize = file.size;
      const uploadPath = `${this._basePath}/${this._globalService.siteId}/${persistedFilename}`;
      const fileRef = this._storage.ref(uploadPath);
      const task = this._storage.upload(uploadPath, file, { customMetadata: { realFilename: filename }});
      const uploadedFile = new UploadedFile({
        filename,
        filesize,
        uploadPath,
        task,
        fileRef, 
        persistedFilename,
        fileKey,
        statusCallback: this.uploadStatusCallback.bind(this)
      })
      
      this._files.push(uploadedFile);
    }

    this._queue.next(this._files);
  }

  public get queue() {
    return this._queue.asObservable();
  }
}
