import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { Observable, from, forkJoin, of } from 'rxjs';
import { mergeMap, map, first } from 'rxjs/operators';
import { Payload, SegmentRef } from '../interfaces';
import { AngularFireDatabaseV1, AngularFireFunctionsV1 } from '../firebase/firebasev1.module';
import { AngularFirestoreV2 } from '../firebase/firebasev2.module';
import { firestore }  from 'firebase/app';

@Injectable()
export class SegmentsService {
  constructor(
    private _global: GlobalService,
    private _rtdb: AngularFireDatabaseV1,
    private _functions: AngularFireFunctionsV1,
    private _database: AngularFirestoreV2
  ) { }

  searchByUniqueId(uniqueId: string, uniqueIdEncoded: string): Observable<Payload<SegmentRef>[]> {
    let uniqueIdFirestoreWorkaround = uniqueIdEncoded;

    if (/\//.test(uniqueIdFirestoreWorkaround))
      uniqueIdFirestoreWorkaround = encodeURIComponent(uniqueIdFirestoreWorkaround);  

    const realtimeDbSegments = this._rtdb
      .list(this._global.partnerRootRef.child('/__segments'))
      .snapshotChanges()
      .pipe(
        map(snapshots => {
          const segmentNames = snapshots.map(snapshot => snapshot.key);
          const queryPromises = segmentNames.map(segmentName => this._global.partnerRootRef.child(`/segments/${encodeURIComponent(uniqueIdEncoded)}__${segmentName}`).once('value'));
          return queryPromises.map(promise => from(promise));
        }),
        mergeMap(queryObservables => queryObservables.length > 0 ? forkJoin(queryObservables) : of([])),
        map(snapshots => snapshots.map(snapshot => { 
          return {
            key: snapshot.key,
            data: snapshot.val()
          } as Payload<SegmentRef>
        }).filter(p => p.data)),
        first()
      );

      const baseDocumentPath = `/${this._global.siteId}/${uniqueIdFirestoreWorkaround}/segment`;
      const firestoreSegments = this._database
        .collection(baseDocumentPath)
        .snapshotChanges()
        .pipe(map(actions => actions.map(action => {
          const segmentId = action.payload.doc.id;
          const data = action.payload.doc.data() as SegmentRef
          const segmentData: SegmentRef = {
            segmentId: segmentId,
            uniqueId: uniqueId,
            uniqueIdEncoded: uniqueIdEncoded,
            uniqueIdEncodedURI: encodeURIComponent(uniqueIdEncoded),
            enrolled: data.enrolled,
            enrolledAt: data.enrolledAt
          }
          
          return {
            key: `${segmentData.uniqueIdEncodedURI}__${segmentId}`,
            secondaryKey: `${baseDocumentPath}/${segmentId}`,
            data: segmentData
          } as Payload<SegmentRef>;
        })),
        first()
      );
        
      return forkJoin([realtimeDbSegments, firestoreSegments]).pipe(map(payloads => {
        const realtimeSegments = payloads[0] || [];
        const firestoreSegments = payloads[1] || [];
      
        return realtimeSegments.concat(firestoreSegments);
      }));
  }

  async getEnrolledDatesAsCSV(startAt, endAt) {
    return this._functions.httpsCallable('callable-getEnrollmentsAsCSV')({
      siteId: this._global.siteId,
      startAt, endAt
    }).toPromise();
  }

  async remove(payload: Payload<SegmentRef>): Promise<void> {
    if (!payload.key) {
      throw Error('[segment.remove()] Missing ref key');
    }

    this._global.loading = true;
    
    const updates = {
      [`/segments/${payload.key}`]: null
    };

    const realtimeDeletePromise = this._rtdb
      .object(this._global.partnerRootRef)
      .update(updates);
    const firestoreDeletePromise = payload.secondaryKey ? this._database
      .doc(payload.secondaryKey)
      .delete() : Promise.resolve();

    try {
      await Promise.all([realtimeDeletePromise, firestoreDeletePromise]);
    } catch (err) {
      this._global.error = err;
    }
    
    this._global.loading = false;
  }

  async update(payload: Payload<SegmentRef>): Promise<void> {
    if (!payload.key) {
      throw Error('[segment.update()] Missing ref key');
    }

    this._global.loading = true;

    const { segmentId, uniqueId, uniqueIdEncodedURI, enrolled } = payload.data;

    if (enrolled) {
      payload.data.enrolledAt = Date.now();
    } else if (!enrolled) {
      payload.data.enrolledAt = null;
    }

    const updateRefs = {
      [`/segments/${payload.key}/segmentId`]: segmentId,
      [`/segments/${payload.key}/uniqueId`]: uniqueId,
      [`/segments/${payload.key}/uniqueIdEncodedURI`]: uniqueIdEncodedURI,
      [`/segments/${payload.key}/enrolled`]: enrolled,
      [`/segments/${payload.key}/enrolledAt`]: payload.data.enrolledAt,
      [`/segments_enrollments/${uniqueIdEncodedURI}__${segmentId}`]: enrolled ? payload.data : null
    };

    const updates = Object.keys(updateRefs).reduce((h, k) => {
      return (typeof updateRefs[k] === 'undefined') ? { ...h } : { ...h, [k]: updateRefs[k] };
    }, {});

    let firestorePromise = Promise.resolve();
    
    if (payload.secondaryKey) {
      const firestoreUpdateData = {
        enrolled: enrolled, 
        enrolledAt: payload.data.enrolledAt ?? firestore.FieldValue.delete()
      };

      firestorePromise = this._database
        .doc(payload.secondaryKey)
        .update(firestoreUpdateData);
    }

    const realtimeDatabasePromise = this._rtdb
      .object(this._global.partnerRootRef)
      .update(updates);
      
    try {
      await Promise.all([realtimeDatabasePromise, firestorePromise]);
    } catch (err) {
      this._global.error = err;
      console.log(err);
    }
    
    this._global.loading = false;
  }
}
