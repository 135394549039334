import { Component, OnInit, OnDestroy } from '@angular/core';
import { BarcodesService } from '../../services/barcodes.service';
import { Subject, of } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { BarcodesIndexOn, Payload, BarcodeRef, Results } from '../../interfaces';
import { takeWhile, switchMap } from 'rxjs/operators';
import { AngularFireFunctionsV1 } from '../../firebase/firebasev1.module';


@Component({
  selector: 'app-barcodes-by-unique-id',
  templateUrl: './barcodes-by-unique-id.component.html',
  styleUrls: ['./barcodes-by-unique-id.component.scss']
})
export class BarcodesByUniqueIdComponent implements OnInit, OnDestroy {

  private _alive = false;

  private _search = new Subject<{ uniqueIdEncoded: string, startKey?: string }>();

  private _loading = false;

  private _getUniqueIdEncoded = this._functions.httpsCallable('callable-getUniqueIdEncoded');

  results: Results = [];

  uniqueId: string;

  constructor(
    private _global: GlobalService,
    private _barcodes: BarcodesService,
    private _functions: AngularFireFunctionsV1
  ) {
    this._search.pipe(
      switchMap(props => {

        // Return empty and skip query if missing values
        if (!props) {
          return of([]);
        }

        const { uniqueIdEncoded, startKey } = props;

        this.loading = true;

        return this._barcodes.search(
          BarcodesIndexOn.uniqueIdEncoded, // <- Search by Unique ID
          uniqueIdEncoded, // <- uniqueId value
          10, // pagination
          startKey // <- optional startKey when jumping pages
        ).pipe(takeWhile(() => this._alive));
      }))
      .subscribe((results: Results) => {
        this.results = results;
        this.loading = false;
      });
  }

  ngOnInit() {
    this._alive = true;
   }

  ngOnDestroy() {
    this._global.loading = false;
    this._alive = false;
    this._search.unsubscribe();
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
    this._global.loading = value;
  }

  async search(uniqueId: string, startKey?: string) {
    this._loading = true;
    this.uniqueId = uniqueId;
    const uniqueIdEncoded = await this._getUniqueIdEncoded({ value: uniqueId }).toPromise();
    this._search.next({ uniqueIdEncoded, startKey });
  }

  clear() {
    this._search.next(null);
  }

  async update(barcode: Payload<BarcodeRef>) {
    return this._barcodes.update(barcode);
  }

  async remove(barcode: Payload<BarcodeRef>) {
    return this._barcodes.remove(barcode);
  }

}
