import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-universal-import-file-headers-chart',
  templateUrl: './universal-import-file-headers-chart.component.html',
  styleUrls: ['./universal-import-file-headers-chart.component.scss']
})
export class UniversalImportFileHeadersChartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  private _isShown = false;

  get isShown() : boolean {
    return this._isShown;
  }

  toggleShown() {
    this._isShown = !this._isShown;
  }

}
