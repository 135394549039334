import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  constructor(private _global: GlobalService) {}

  ngOnInit() {}

  get partners(): string[] {
    return this._global.partners;
  }
}
