import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { SegmentRef, Payload } from '../../interfaces';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss']
})
export class SegmentComponent implements OnChanges {
  @Output() updated = new EventEmitter<Payload<SegmentRef>>();

  @Output() removed = new EventEmitter<Payload<SegmentRef>>();

  @Input() payload: Payload<SegmentRef>;

  readonly: boolean;

  form: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private _dialog: MatDialog
  ) {
    this.readonly = true;

    this.form = this._fb.group({
      enrolled: false,
      enrolledAt: [{ value: null, disabled: true }],
      segmentId: [{ value: '', disabled: true }],
      uniqueId: [{ value: '', disabled: true }],
      uniqueIdEncoded: [{ value: '', disabled: true }],
      uniqueIdEncodedURI: [{ value: '', disabled: true }]
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const {
      currentValue: currentData,
      previousValue: previousData
    } = changes.payload

    if (JSON.stringify(currentData) !== JSON.stringify(previousData)) {
      this.form.patchValue(currentData.data);
    }
  }

  handleEnroll(checked: boolean) {
    this.form.patchValue({
      enrolledAt: checked === true ? this.payload.data.enrolledAt : null
    });
    this.form.markAsDirty();
  }

  handleToggleEditMode() {
    this.readonly = !this.readonly;
  }

  handleSubmit() {
    this.payload.data.enrolled = this.form.value.enrolled;
    this.updated.emit(this.payload);
    this.readonly = true;
  }

  handleResetEditing() {
    this.form.reset(this.payload.data);
    this.readonly = true;
  }

  handleDelete() {
    this._dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Segment Offer',
        body: `Are you sure you want to delete
               segment ${this.payload.data.segmentId} from user ${this.payload.data.uniqueId}?`,
        // Event emitter does not return a promise, which causes an error with ConfirmationDialogComponent
        // This is a work around until more time can be spent to re-think this component
        accept: () => { this.removed.emit(this.payload); return Promise.resolve() }
      }
    });
  }
}
