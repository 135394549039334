import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isItExpired'
})
export class IsItExpiredPipe implements PipeTransform {
  transform(timestamp: number, args?: any): any {
    const current = new Date().getTime();
    return timestamp < current;
  }
}
