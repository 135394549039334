import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Credentials } from '../../interfaces';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent {
  @Output() handleSubmit = new EventEmitter<Credentials>();

  @Input() error: string;

  onSubmit(crendentials: Credentials) {
    this.handleSubmit.emit(crendentials);
  }
}
