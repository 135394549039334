import { Injectable } from '@angular/core';
import { User } from 'firebase/app';
import { Credentials } from '../interfaces';
import { AngularFireAuthV1 } from '../firebase/firebasev1.module';

@Injectable()
export class AuthService {
  user: User;

  flash: string;

  constructor(private _firebaseAuth: AngularFireAuthV1) {
    this._firebaseAuth.authState.subscribe(user => {
      this.user = user;
    });
  }

  get token(): string {
    return this.user.toJSON()['stsTokenManager'].accessToken;
  }

  login(crendetials: Credentials) {
    return this._firebaseAuth.signInWithEmailAndPassword(
      crendetials.email,
      crendetials.password
    ).then(() => {
      this.flash = null;
    });
  }

  reset(email: string) {
    return this._firebaseAuth.sendPasswordResetEmail(email)
      .then(() => {
        this.flash = `A confirmation link have been sent to ${email}. Follow the instructions to change your password.`;
      });
  }

  logout() {
    return this._firebaseAuth.signOut().then(() => {
      this.flash = 'You have successfully logged-out';
    });
  }
}
