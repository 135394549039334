import { AngularFireAction } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { DatabaseReference, DataSnapshot } from '@angular/fire/database/interfaces';

export interface Credentials {
  email: string;
  password: string;
}

export interface DateRange {
  fromDate: Date;
  toDate: Date;
}

export interface Payload<V> {
  data: V;
  key: string;
  secondaryKey?: string
}

export interface DialogData {
  title: string;
  body: string;
  loading: Observable<number>;
  accept: () => Promise<any>;
  cancel: () => Promise<any>;
}

export enum FirebaseApps {
  V1 = 'firebase',
  V2 = 'specialOffers'
}

export interface Results extends Array<AngularFireAction<DataSnapshot>> {
  next?: {
    value: any;
    key: string;
  };
}

export interface LineItems {
  lineItemEN: string|number;
  lineItemES: string|number;
  lineItemFR: string|number;
  expirationDate: string;
}

export interface ListFile {
  _key: string;
  [key: string]: string;
}

// Firebase Realtime Database Refs

export interface QueueRef extends DatabaseReference {
  count: number;
  total: number;
  endedAt: number;
  lastUpdatedAt: number;
  startedAt: number;
  createdAt: number;
  author: string;
  running: boolean;
  data: { [key: string]: any };
  type: QueueType;
}

export interface BarcodeRef extends DatabaseReference {
  barcodeNumber: string;
  barcodeNumber_month: string;
  expirationDate: string;
  expiresAt: number;
  lineItemIdEN: string;
  lineItemIdES: string;
  lineItemIdFR: string;
  onlinePromoCode: string;
  onlinePromoCode_month: string;
  redeemed: boolean;
  segmentId: string;
  uniqueId: string;
  uniqueIdEncoded: string;
  uniqueIdEncodedURI: string;
}

export interface SegmentRef {
  enrolledAt?: number;
  enrolled?: boolean;
  segmentId: string;
  uniqueId: string;
  uniqueIdEncoded: string;
  uniqueIdEncodedURI: string;
}

export interface ACQSegmentRef {
  segmentId: string;
  unused_codes_count: number;
  total_imported_codes: number;
}

export enum BarcodesIndexOn {
  uniqueIdEncoded = 'uniqueIdEncoded'
}

export enum SegmentsIndexOn {
  uniqueIdEncoded = 'uniqueIdEncoded'
}

export enum QueueIndexOn {
  endedAt = 'endedAt',
  lastUpdatedAt = 'lastUpdatedAt',
  createdAt = 'createdAt',
  startedAt = 'startedAt',
  running = 'running',
  type = 'type'
}

export enum QueueType {
  barcodeNew = 'barcode.new',
  barcodeRedeem = 'barcode.redeem',
  barcodePurge = 'barcode.purge',
  segmentNew = 'segment.new',
  segmentPurge = 'segment.purge',
  dynamicContentNew = 'dynamicContent.new',
  dynamicContentPurge = 'dynamicContent.purge',
}
