import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Payload, BarcodeRef } from '../../interfaces';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidateBarcodeExpirationDate } from '../../validators/expiration-date.validator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss']
})
export class BarcodeComponent implements OnChanges {
  @Output() updated = new EventEmitter<Payload<BarcodeRef>>();
  @Output() removed = new EventEmitter<Payload<BarcodeRef>>();
  @Input() key: string;
  @Input() data: BarcodeRef;

  readonly: boolean;

  form: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private _dialog: MatDialog
  ) {
    this.readonly = true;
    this.form = this._fb.group({
      barcodeNumber_month: ['', Validators.required],
      barcodeNumber: ['', Validators.required],
      expirationDate: ['', [Validators.required, ValidateBarcodeExpirationDate]],
      expiresAt: { value: '', disabled: true },
      lineItemIdEN: ['', Validators.required],
      lineItemIdES: ['', Validators.required],
      lineItemIdFR: ['', Validators.required],
      onlinePromoCode_month: ['', Validators.required],
      onlinePromoCode: ['', Validators.required],
      redeemed: false,
      segmentId: ['', Validators.required],
      uniqueId: { value: '', disabled: true },
      uniqueIdEncoded: { value: '', disabled: true },
      uniqueIdEncodedURI: { value: '', disabled: true }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const {
      currentValue: currentData,
      previousValue: previousData
    } = changes.data;

    if (JSON.stringify(currentData) !== JSON.stringify(previousData)) {
      this.form.patchValue(currentData);
    }
  }

  handleToggleEditMode() {
    this.readonly = !this.readonly;
  }

  handleSubmit() {
    const data = {
      ...this.data,
      ...this.form.value
    };
    this.updated.emit({ key: this.key, data });
    this.readonly = true;
  }

  handleResetEditing() {
    this.form.reset(this.data);
    this.readonly = true;
  }

  handleDelete() {
    this._dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Barcode Offer',
        body: `Are you sure you want to delete barcode ${this.data.barcodeNumber}
              from user ${this.data.uniqueId}?`,
        accept: () => this.removed.emit({ key: this.key, data: this.data })
      }
    });
  }
}
