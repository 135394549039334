import { Component, ViewEncapsulation } from '@angular/core';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-segments',
  templateUrl: './segments.component.html',
  styleUrls: ['./segments.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SegmentsComponent {

  constructor(private _global: GlobalService) {
    this._global.title = 'Segments';
  }

}
