import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginFormComponent } from './login-form/login-form.component';
import { MaterialModule } from '../material/material.module';
import { PipesModule } from '../pipes/pipes.module';
import { BarcodeComponent } from './barcode/barcode.component';
import { RouterModule } from '@angular/router';
import { SegmentComponent } from './segment/segment.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { BarcodesListComponent } from './barcodes-list/barcodes-list.component';
import { SegmentsListComponent } from './segments-list/segments-list.component';
import { PaginationComponent } from './pagination/pagination.component';
import { FileInputComponent } from './file-input/file-input.component';
import { QueueJobsListComponent } from './queue-jobs-list/queue-jobs-list.component';
import { ResetPasswordFormComponent } from './reset-password-form/reset-password-form.component';
import { DynamicContentOffersComponent } from './dynamic-content/dynamic-content-offers/dynamic-content-offers.component';
import { NewDynamicContentOfferFileUploadComponent } from './dynamic-content/new-dc-offer-file-upload/new-dc-offer-file-upload.component';
import { NewDynamicContentOfferFormComponent } from './dynamic-content/new-dc-offer-input/new-dc-offer-form.component';
import { NewDynamicContentOfferDragDropComponent} from './dynamic-content/new-dc-offer-drag-drop/new-dc-offer-drag-drop.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SearchMarketingOffersComponent } from './search-marketing-offers/search-marketing-offers.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { AcqOfferChartComponent } from './acq-offer-chart/acq-offer-chart.component';
import { AcqOfferInfoComponent } from './acq-offer-info/acq-offer-info.component';
import { LottieModule } from 'ngx-lottie'
import player from 'lottie-web/build/player/lottie_svg';
import { UploaderComponent } from './uploader/uploader.component';
import { UploadTaskComponent } from './upload-task/upload-task.component';
import { DropzoneDirective } from '../directives/dropzone.directive';
import { GtmOptInComponent } from './gtm-opt-in/gtm-opt-in.component';
import { UniversalImportFileHeadersChartComponent } from './universal-import-file-headers-chart/universal-import-file-headers-chart.component';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}
@NgModule({
  imports: [
    LottieModule.forRoot({
      player: playerFactory,
      useCache: true,
    }),
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
    RouterModule,
    ReactiveFormsModule,
    DragDropModule,
    BrowserAnimationsModule,
    NgxJsonViewerModule,
  ],
  exports: [
    BarcodeComponent,
    LoginFormComponent,
    ResetPasswordFormComponent,
    SegmentComponent,
    BarcodesListComponent,
    SegmentsListComponent,
    PaginationComponent,
    FileInputComponent,
    QueueJobsListComponent,
    MaterialModule,
    ReactiveFormsModule,
    DynamicContentOffersComponent,
    NewDynamicContentOfferFileUploadComponent,
    NewDynamicContentOfferFormComponent,
    NewDynamicContentOfferDragDropComponent,
    SearchMarketingOffersComponent,
    AcqOfferChartComponent,
    AcqOfferInfoComponent,
    UploaderComponent,
    UploadTaskComponent,
    DropzoneDirective
  ],
  declarations: [
    LoginFormComponent,
    ResetPasswordFormComponent,
    BarcodeComponent,
    SegmentComponent,
    ConfirmationDialogComponent,
    BarcodesListComponent,
    SegmentsListComponent,
    PaginationComponent,
    FileInputComponent,
    QueueJobsListComponent,
    DynamicContentOffersComponent,
    NewDynamicContentOfferFileUploadComponent,
    NewDynamicContentOfferFormComponent,
    NewDynamicContentOfferDragDropComponent,
    SearchMarketingOffersComponent,
    AcqOfferChartComponent,
    AcqOfferInfoComponent,
    GtmOptInComponent,
    UniversalImportFileHeadersChartComponent,
    UploaderComponent,
    UploadTaskComponent,
    DropzoneDirective
  ],
  entryComponents: [
    ConfirmationDialogComponent
  ]
})
export class ComponentsModule {}
