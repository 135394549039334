import { Injectable } from '@angular/core';
import { AngularFireDatabaseV1 } from '../firebase/firebasev1.module';
import { DatabaseReference } from '@angular/fire/database/interfaces';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class GlobalService {

  databases: {};

  error: string;

  loading: boolean;

  siteId: string;

  success: string;

  private _titleSubject = new BehaviorSubject<string>(null);

  constructor(
    private _rtdb: AngularFireDatabaseV1
  ) {
    this._rtdb.object('/databases')
      .valueChanges()
      .subscribe(databases => {
        this.databases = databases;
      });
  }

  getTitleObservable(): Observable<string> {
    return this._titleSubject.asObservable();
  }

  set title(value: string) {
    this._titleSubject.next(value);
  }

  get title(): string {
    return this._titleSubject.getValue();
  }

  get ready(): boolean {
    return !!this.databases;
  }

  get partners(): string[] {
    return this.databases ? Object.keys(this.databases) : null;
  }

  get partnerRootRef(): DatabaseReference {
    const databaseID = this.databases[this.siteId];
    return this._rtdb.database.app
      .database(`https://${databaseID}.firebaseio.com/`)
      .ref('/');
  }

  downloadFile(data, { filename = 'file.txt', type = 'text/plain;charset=utf-8;'}) {
    const blob = new Blob([data], { type });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    if (link.download !== undefined) {
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(url);
    }
  }
}
