import { Injectable } from '@angular/core';
import { GlobalService } from '../services/global.service';
// import { AuthService } from './auth.service';
import { Authv2Service } from './authv2.service';
// import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireFunctionsV2 } from '../firebase/firebasev2.module';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private _globalService: GlobalService,
    private _authV2: Authv2Service,
    private _functionsV2: AngularFireFunctionsV2
  ) {}

  private _callableEncode = this._functionsV2.httpsCallable('encode');
  private _callableGetMarketingData = this._functionsV2.httpsCallable('getMarketingData');

  async encodeDMARuniqueId(id: string): Promise<any> {
    const authToken  = this._authV2.token;
    const user = this._authV2.user;
    const data = await this._callableEncode({ id,
                                              user: { email: user.email },
                                              authToken })
                                              .toPromise();
    return data;
  }

  async searchMarketingData(userId: string): Promise<any> {
    const { siteId } = this._globalService;
    const authToken  = this._authV2.token;
    const user = this._authV2.user;

    const dmarUniqueId = userId;

    const data = await this._callableGetMarketingData({ dmarUniqueId,
                                                        siteId,
                                                        user: { email: user.email },
                                                        authToken })
                                                        .toPromise();

    return data;
  }
}
