import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { ActivatedRoute, Params, Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Authv2Service } from '../../services/authv2.service';
import { User } from '@firebase/auth-types';
import { Subscription, Subject } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { QueueService } from '../../services/queue.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { takeWhile, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  private _alive = false;
  private _params: Subscription;
  private _breakpoints: Subscription;
  private _queueCount: Subscription;
  private _titleNotifier: Subject<void> = new Subject<void>();
  private _title: string;

  inQueue = 0;

  isMobile: boolean;

  constructor(
    private _auth: AuthService,
    private _authv2: Authv2Service,
    private _global: GlobalService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _queue: QueueService,
    private _breakpointObserver: BreakpointObserver,
    private _http: HttpClient
  ) {
    this._global.title = 'Dashboard';
    /**
     * Restore components on route change
     */
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;

    this._router.events
      .subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
            window.scrollTo(0, 0);
        }
      });

    this._params = this._route.params
      .subscribe((params: Params) => {
        const { siteId } = params;
        this.siteId = siteId;
      });

    this._breakpoints = this._breakpointObserver
      .observe([  Breakpoints.HandsetPortrait ])
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this._queueCount = this._queue.count().pipe(takeWhile(() => this._alive))
      .subscribe(count => {
        this.inQueue = count;
      });

    window['howdy'] = () => this._http.get(environment.endpoints.howdy).toPromise();
  }

  ngOnInit() {
    this._alive = true;
    this._global.getTitleObservable()
          .pipe(takeUntil(this._titleNotifier))
          .subscribe((value) => setTimeout(() => this._title = value, 0));
  }

  ngOnDestroy() {
    this._global.loading = false;
    this._alive = false;
    this._params.unsubscribe();
    this._breakpoints.unsubscribe();
    this._queueCount.unsubscribe();
    this._titleNotifier.next();
    this._titleNotifier.complete();
  }

  get title(): string {
    return this._title;
  }

  get siteId(): string {
    return this._global.siteId;
  }

  set siteId(siteId: string) {
    this._global.siteId = siteId;
  }

  get partners(): string[] {
    return this._global.partners;
  }

  get ready(): boolean {
    return this._global.ready;
  }

  get userAuth(): User {
    return this._auth.user;
  }

  get loading(): boolean {
    return this._global.loading;
  }

  logout() {
    this._auth.logout();
    this._authv2.logout();
  }

}
