import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettierJSON'
})
export class PrettierJSONPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    const prettierLi = Object.keys(value).reduce((s, k) => {
      const v = value[k];
      return s += `<li><strong class="strong">${k}</strong>: ${JSON.stringify(v)}</li>`;
    }, '');

    return `<ul>${prettierLi}</ul>`;
  }

}
