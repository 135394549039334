import { AngularFireStorageReference, AngularFireUploadTask } from "@angular/fire/storage"
import { UploadTaskSnapshot } from "@angular/fire/storage/interfaces"
import { FirebaseError } from "firebase";
import { Observable } from "rxjs"
import { finalize } from "rxjs/operators";

export type UploadedFileInformation = {
  filename: string, 
  uploadPath: string,
  filesize: number, 
  task: AngularFireUploadTask,
  fileRef: AngularFireStorageReference,
  persistedFilename: string,
  fileKey: string,
  statusCallback: {
    (uploadedFile: UploadedFile): void
  }
}

export enum UploadStatus {
  Uploading,
  Uploaded,
  Error,
  Cancelled,
  Paused,
  Inspecting, 
  Done,
  Importing
}

export class UploadedFile {
  private readonly _fileInformation: UploadedFileInformation;
  private readonly _percentageChanges: Observable<number>;
  private readonly _snapshotChanges: Observable<UploadTaskSnapshot>;
  private _downloadUrl: string;
  private _uploadStatus: UploadStatus;
  
  public offerName: string;
  
  fileInspection: any = null;

  constructor(uploadedFileInformation: UploadedFileInformation) {
    this._fileInformation = uploadedFileInformation;
    this._percentageChanges = this._fileInformation.task.percentageChanges()
    this._snapshotChanges = this._fileInformation.task.snapshotChanges().pipe(
      finalize(() => {
        if (this.isUploading) {
          this._fileInformation.fileRef.getDownloadURL().toPromise().then(value => {    
            this._downloadUrl = value;
            this._uploadStatus = UploadStatus.Uploaded;
            this._fileInformation.statusCallback(this);
          }).catch((err) => {
            console.log(err);
          });
        }  
    }));

    this._uploadStatus = UploadStatus.Uploading;
    
    this._fileInformation.task.catch((err: FirebaseError) => {
      if (err.code !== "storage/canceled") {
        this._uploadStatus = UploadStatus.Error;
        this._fileInformation.statusCallback(this);
      }
    });
  }

  public get percentage(): Observable<number> {
    return this._percentageChanges;
  }

  public get snapshot(): Observable<UploadTaskSnapshot> {
    return this._snapshotChanges;
  }

  public get filename(): string {
    return this._fileInformation.filename;
  }

  public get downloadUrl(): string {
    return this._downloadUrl;
  } 

  public get persistedFilename(): string {
    return this._fileInformation.persistedFilename;
  }

  public get uploadPath(): string {
    return this._fileInformation.uploadPath;
  }

  public get fileKey(): string {
    return this._fileInformation.fileKey;
  }

  public get isUploading(): boolean {
    return this._uploadStatus === UploadStatus.Uploading;
  }

  public get isImporting(): boolean {
    return this._uploadStatus === UploadStatus.Importing;
  }

  public get isError(): boolean {
    return this._uploadStatus === UploadStatus.Error;
  }

  public get isUploaded(): boolean {
    return this._uploadStatus === UploadStatus.Uploaded;
  }

  public get isCancelled(): boolean {
    return this._uploadStatus === UploadStatus.Cancelled;
  }

  public get isPaused(): boolean {
    return this._uploadStatus === UploadStatus.Paused;
  }

  public get isInspecting(): boolean {
    return this._uploadStatus === UploadStatus.Inspecting || this.isUploaded;
  }

  public get isDone(): boolean {
    return this._uploadStatus === UploadStatus.Done;
  }

  public setStatus(status: UploadStatus) {
    this._uploadStatus = status;
  }

  public resume() {
    if (this._fileInformation.task.resume()) {
      this._uploadStatus = UploadStatus.Uploading;
      this._fileInformation.statusCallback(this);
    }
  }

  public pause() {
    if (this._fileInformation.task.pause()) {
      this._uploadStatus = UploadStatus.Paused;
      this._fileInformation.statusCallback(this);
    }
  }

  public cancel() {
    if (this._fileInformation.task.cancel()) {
      this._uploadStatus = UploadStatus.Cancelled;
      this._fileInformation.statusCallback(this);
    }
  }

  public inspect() {
    this._uploadStatus = UploadStatus.Inspecting;
    this._fileInformation.statusCallback(this);
  }

  public done() {
    this._uploadStatus = UploadStatus.Done;
    this._fileInformation.statusCallback(this);
  }
}
