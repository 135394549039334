import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../interfaces';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

  isLoading = false;

  error: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() { }

  get loading(): Observable<number> {
    return this.data.loading;
  }

  async accept() {
    if (this.data.accept) {
      this.error = null;
      this.isLoading = true;

      await this.data.accept()
        .catch(error => {
          this.error = error.message;
          throw new Error(error.message);
        });
    }

    return this.dialogRef.close();
  }

  async cancel() {
    if (this.data.cancel) {
      await this.data.cancel();
    }
    return this.dialogRef.close();
  }
}
