import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireFunctions, REGION, ORIGIN } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from '../../environments/environment';
import { FirebaseApps } from '../interfaces';
import { NgModule, Injectable, NgZone, PLATFORM_ID } from '@angular/core';

const v1AppName = FirebaseApps.V1;
const v1AppOptions = environment[v1AppName];
const functionsRegion = environment.functionsRegion;
// const functionsOrigin = 'https://api-v1.dmar-specialoffers.com';
const functionsOrigin = null;

@Injectable()
export class AngularFireAuthV1 extends AngularFireAuth { }

export function angularFireAuthV1Factory(platformId: Object, zone: NgZone): AngularFireAuthV1 {
  return new AngularFireAuthV1(v1AppOptions, v1AppName, platformId, zone);
}

@Injectable()
export class AngularFireDatabaseV1 extends AngularFireDatabase { }

export function angularFireDatabaseV1Factory(platformId: Object, zone: NgZone): AngularFireDatabaseV1 {
  const { databaseURL } = v1AppOptions;
  return new AngularFireDatabaseV1(v1AppOptions, v1AppName, databaseURL, platformId, zone);
}

@Injectable()
export class AngularFireFunctionsV1 extends AngularFireFunctions { }

export function angularFireFunctionsV1Factory(platformId: Object, zone: NgZone): AngularFireFunctionsV1 {
  return new AngularFireFunctionsV1(v1AppOptions, v1AppName, zone, functionsRegion, functionsOrigin);
}

@Injectable()
export class AngularFireStorageV1 extends AngularFireStorage { }

export function angularFireStorageV1Factory(platformId: Object, zone: NgZone): AngularFireStorageV1 {
  const { storageBucket } = v1AppOptions;
  return new AngularFireStorageV1(v1AppOptions, v1AppName, storageBucket, platformId, zone);
}

@NgModule({
  providers: [
    {
      provide: ORIGIN,
      useValue: functionsOrigin,
    },
    {
      deps: [PLATFORM_ID, NgZone],
      provide: AngularFireAuthV1,
      useFactory: angularFireAuthV1Factory,
    },
    {
      deps: [PLATFORM_ID, NgZone],
      provide: AngularFireDatabaseV1,
      useFactory: angularFireDatabaseV1Factory,
    },
    {
      deps: [PLATFORM_ID, NgZone],
      provide: AngularFireFunctionsV1,
      useFactory: angularFireFunctionsV1Factory,
    },
    {
      deps: [PLATFORM_ID, NgZone],
      provide: AngularFireStorageV1,
      useFactory: angularFireStorageV1Factory,
    },
    {
      provide: REGION,
      useValue: functionsRegion
    }
  ]
})
export class FirebaseModuleV1 { }
