import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  @Output() change = new EventEmitter<any>();

  @Input() next: any;

  constructor() { }

  steps = [];

  ngOnInit() { }

  triggerNext() {
    if (!this.next) {
      return;
    }

    this.steps.push(this.next);
    this.change.emit(this.next);
  }

  triggerPrevious() {
   this.steps.pop();
   const next = this.steps.length === 0 ? {} : { ...this.steps[this.steps.length - 1] };
   this.change.emit(next);
  }

}
