import { Component, OnInit, OnDestroy } from '@angular/core';
import { QueueType, ListFile } from '../../interfaces';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { QueueService } from '../../services/queue.service';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { takeWhile } from 'rxjs/operators';
import { AngularFireDatabaseV1 } from '../../firebase/firebasev1.module';

@Component({
  selector: 'app-barcodes-purge',
  templateUrl: './barcodes-purge.component.html',
  styleUrls: ['./barcodes-purge.component.scss']
})
export class BarcodesPurgeComponent implements OnInit, OnDestroy {

  private _alive = false;
  private _listFiles: Subscription;

  listFile: string;
  listFiles: ListFile[] = [];

  constructor(
    private _dialog: MatDialog,
    private _queue: QueueService,
    private _rtdb: AngularFireDatabaseV1,
    private _global: GlobalService
  ) {
    this._listFiles = this._rtdb.list(this._global.partnerRootRef.child('/__barcodes'))
      .snapshotChanges()
      .pipe(takeWhile(() => this._alive))
      .subscribe((results) => {
        this.listFiles = results.map(x => (<ListFile>{ _key: x.key, ...(x.payload.val() as { [key: string]: string }) }));
      });
  }

  ngOnInit() {
    this._alive = true;
   }

  ngOnDestroy() {
    this._global.loading = false;
    this._alive = false;
    this._listFiles.unsubscribe();
  }

  get siteId(): string {
    return this._global.siteId;
  }

  isExpired(value) {
    return value < Date.now();
  }

  purge({ fileKey, filePath }) {
    this._dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Purge Expired Barcode Offers',
        body: `Are you sure you want to delete all offers belonging to ${this.listFile}?`,
        accept: async () => {
          return this._queue.addToPendingJobs(QueueType.barcodePurge, {
            fileKey,
            filePath
          });
        }
      }
    });
  }

}
