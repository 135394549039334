import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { QueueService } from '../../services/queue.service';
import { QueueType } from '../../interfaces';
import { GlobalService } from '../../services/global.service';
import { AngularFireStorageV1 } from '../../firebase/firebasev1.module';
import { FileInputComponent } from '../../components/file-input/file-input.component';
import { FormControl } from '@angular/forms';
import { DynamicContentService } from '../../services/dynamic-content.service';
import { AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { AngularFireStorageV2 } from '../../firebase/firebasev2.module';
import { finalize } from 'rxjs/operators';
import { OfferType, OfferMetaDataDoc } from '../../models/enums';

@Component({
  selector: 'app-segments-import',
  templateUrl: './segments-import.component.html',
  styleUrls: ['./segments-import.component.scss']
})
export class SegmentsImportComponent implements OnInit {

  file: File;
  enrollmentWithGTM = true;
  private _fileUploaded = false;
  uploadTask: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  uploadSnapshot: Observable<any>;

  @ViewChild('gtmFileInput', { static: false }) fileInputGTM: FileInputComponent;
  @ViewChild('acxiomFileInput', { static: false }) fileInputAxiom: FileInputComponent;
  @ViewChild('form', { static: false }) importForm: FormControl;

  constructor(
    private _dialog: MatDialog,
    private _queue: QueueService,
    private _storage: AngularFireStorageV1,
    private _storageV2: AngularFireStorageV2,
    private _global: GlobalService,
    private _dynamicContentService: DynamicContentService
  ) { }

  ngOnInit() { }

  get fileUploaded(): boolean {
    return this._fileUploaded;
  }

  get siteId(): string {
    return this._global.siteId;
  }

  attachCSVFile(file: File) {
    if (!file) {
      return;
    }
    this._fileUploaded = false;
    this.file = file;
  }

  toggleGTMenrollment() {
    this.enrollmentWithGTM = !this.enrollmentWithGTM;
  }

  get readyToSubmit(): boolean {
    return this.importForm.valid && !this.fileInputAxiom?.isInvalid && !this.fileInputGTM?.isInvalid;
  }

  uploadFile() {
    console.log('UPLOAD FILE');
    const filePath = `csv/${OfferMetaDataDoc.SPECIAL_OFFER}/${this.file.name}`;

    if (this.enrollmentWithGTM) {
      this.uploadTask = this._storageV2.upload(filePath, this.file);
      this.uploadProgress = this.uploadTask.percentageChanges();
      this.uploadSnapshot = this.uploadTask.snapshotChanges().pipe(
        finalize( async() => {
          this._fileUploaded = true;
          this._dynamicContentService.setCSVpath(filePath);
          console.log('file uploaded to: ', filePath);
        })
      );
      this.uploadSnapshot.subscribe(
        res => {
          console.log(res);
        }, err => {
          console.log(err);
        }
      );
    }
  }

  triggerSubmit(file) {
    if (!this.readyToSubmit) {
      return;
    }

    const filePath = `csv/${file.name}`;

    if (!this.enrollmentWithGTM) {
      this.uploadTask = this._storage.upload(filePath, file);
      this.uploadTask.pause();
    }

    this._dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Import Segment Offers',
        body: `Are you sure you want to import segments offers from ${file.name}.`,
        accept: async () => {
          this.uploadTask.resume();

          const _file = await this.uploadTask;

          if (!this.enrollmentWithGTM) {
            // legacy segment processing to the queue
            return this._queue.addToPendingJobs(QueueType.segmentNew, {
              filePath: _file.metadata.fullPath
            }).catch((async error => {
              await _file.ref.delete();
              throw new Error(error);
            }));
          } else {
            // set the data on the service it needs to init a new offer
            // the segmentId is the first item in the first row of data
            const segmentId = this.fileInputGTM.fileSampleData[0];
            this._dynamicContentService.newOfferData.newOfferName = segmentId;
            // loop over imported sample data pass as vars so we can show in UI
            this.fileInputGTM.fileSampleData.map(key => {
              console.log(key);
              this._dynamicContentService.addNewOfferVar(key);
            });

            // for segment offers we know the field mappings so we can just set them
            this._dynamicContentService.newOfferData.fieldMappings = '0|segmentId,1|KEY,2|activationCode,3|programName,4|campaignCode';

            // initialize the new offer data with the data that is set in the service
            await this._dynamicContentService.initNewOfferMetaData(OfferType.SPECIAL_OFFER, OfferMetaDataDoc.SPECIAL_OFFER);
            // kick off a dataflow job
            return this._dynamicContentService.invokeDataLoad(OfferType.SPECIAL_OFFER, OfferMetaDataDoc.SPECIAL_OFFER)
              .catch((async error => {
                throw new Error(error);
              }));
          }
        },
        cancel: async () => this.uploadTask.cancel(),
        loading: this.uploadTask.percentageChanges()
      }
    });
  }

}
