import { Component, OnInit, Input, ViewEncapsulation, forwardRef } from '@angular/core';
import Papa from 'papaparse';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
       provide: NG_VALUE_ACCESSOR,
       useExisting: forwardRef(() => FileInputComponent),
       multi: true
    }
  ]
})
export class FileInputComponent implements OnInit {

  @Input() accept: string;
  @Input() label: string;
  @Input() loading = false;
  @Input() name: string;
  @Input() options;
  @Input() value = '';
  @Input() validateCSVHeaders = null;


  isInvalid = false;

  fileName: string = null;

  private _fileHeaders = [];
  private _fileFirstRowData = [];

  constructor() { }

  ngOnInit() { }

  onChange(event: any) { }

  onTouched() { }

  writeValue(value: string) {
    this.value = value;
  }

  get fileHeaders(): Array<string> {
    return this._fileHeaders;
  }

  get fileSampleData(): Array<string> {
    return this._fileFirstRowData;
  }

  get fileHeadersAreCorrect(): boolean {
    if  (!this.isInvalid && this.fileName != null) {
      return true;
    } else {
      return false;
    }
  }

  onFileAttached(e: any) {
    const file = e.target.files[0];
    this.fileName = file ? file.name : null;

    // we want to parse the file first
    this.parseFile(file, (importObj: Object) => {
      const keys = Object.keys(importObj);
      this.isInvalid = false;
      this._fileHeaders = [];
      this._fileFirstRowData = [];

      keys.map(key => {
        const value = importObj[key];
        console.log(key, value);
        this._fileHeaders.push(key);
        this._fileFirstRowData.push(value);
      });

        let headerCount = 0;
      // need check to swap headers for segment specialOffers
      this._fileHeaders.length > this.validateCSVHeaders.length ?
        headerCount = this._fileHeaders.length : headerCount = this.validateCSVHeaders.length;
        for (let index = 0; index < headerCount; index++) {
        if (this.validateCSVHeaders.indexOf(this._fileHeaders[index]) === -1) {
            this.isInvalid = true;
            break;
          }
        }

    });
      }

  parseFile(_file, _callBack) {
    Papa.parse(_file, {
      header: true,
      complete: function(results) {
        _callBack(results.data[0]);
    }
    });
  }

  registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }

  registerOnTouched(fn: () => void): void { this.onTouched = fn; }

}
