import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { UploadedFile } from '../../models/uploadedfile.model';
import { UploadService } from '../../services/upload.service';

@Component({
  selector: 'uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [MatExpansionPanel]
})
export class UploaderComponent implements OnInit, OnDestroy  {
  isHovering: boolean;
  queue: Observable<UploadedFile[]>;
  queueReversed: Observable<UploadedFile[]>;
  $destroyed: Subject<void>;
  hasFiles: Observable<boolean>;

  constructor(public uploadService: UploadService) { 
    
  }
  
  getFileType(uploadedFile: UploadedFile) {
    switch (uploadedFile.fileInspection.fileType) {
      case "segment":
        return "Segment List File";
      case "specialOffers":
        return "GTM Segment List File";
      case "dynamicOfferContent":
        return "Dynamic Offer Content List File";
      default:
        return "Unknown File Type"
    }
  }

  trackByFn(uploadedFile: UploadedFile) {
    return uploadedFile.fileKey;
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  ngOnInit() {
    this.$destroyed = new Subject<void>();
    this.queue = this.uploadService.queue;
    this.hasFiles = this.queue.pipe(takeUntil(this.$destroyed), map(files => {
      return files.length > 0;
    }));
    this.queueReversed = this.queue.pipe(takeUntil(this.$destroyed), map(files => {
      return files.reverse()
    }))
  }

  ngOnDestroy() {
    this.uploadService.clear();
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  onDrop(files: FileList) {
    this.uploadService.addToQueue(files);
  }

  importFile(file: UploadedFile) {
    this.uploadService.import(file);
  }
}
