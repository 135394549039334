import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ContainersModule } from '../containers/containers.module';
import { PipesModule } from '../pipes/pipes.module';
import { ComponentsModule } from '../components/components.module';
import { AuthGuard } from '../services/auth.guard';
import { BarcodesComponent } from '../containers/barcodes/barcodes.component';
import { LoginComponent } from '../containers/login/login.component';
import { PartnersComponent } from '../containers/partners/partners.component';
import { DashboardComponent } from '../containers/dashboard/dashboard.component';
import { SegmentsComponent } from '../containers/segments/segments.component';
import { SegmentsByUniqueIdComponent } from '../containers/segments-by-unique-id/segments-by-unique-id.component';
import { BarcodesByUniqueIdComponent } from '../containers/barcodes-by-unique-id/barcodes-by-unique-id.component';
import { SegmentsEnrolledComponent } from '../containers/segments-enrolled/segments-enrolled.component';
import { BarcodesPurgeComponent } from '../containers/barcodes-purge/barcodes-purge.component';
import { QueueComponent } from '../containers/queue/queue.component';
import { BarcodesRedeemedComponent } from '../containers/barcodes-redeemed/barcodes-redeemed.component';
import { SegmentsImportComponent } from '../containers/segments-import/segments-import.component';
import { BarcodesImportComponent } from '../containers/barcodes-import/barcodes-import.component';
import { SegmentsPurgeComponent } from '../containers/segments-purge/segments-purge.component';
import { PageNotFoundComponentComponent } from '../page-not-found-component/page-not-found-component.component';
import { ResetPasswordComponent } from '../containers/reset-password/reset-password.component';
import { DynamicContentComponent } from '../containers/dynamic-content/dynamic-content.component';
import { DynamicContentImportComponent } from '../containers/dynamic-content-import/dynamic-content-import.component';
import { DynamicContentDashboardComponent } from '../containers/dynamic-content-dashboard/dynamic-content-dashboard.component';
import { UploadComponent } from '../containers/upload/upload.component';
import { GtmOptInSegmentsComponent } from '../containers/gtm-opt-in-segments/gtm-opt-in-segments.component';
import { GtmOptInComponent } from '../components/gtm-opt-in/gtm-opt-in.component';
import { AcqDashboardComponent } from '../containers/acq-dashboard/acq-dashboard.component';

// Routing
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full'},
  { path: 'login', component: LoginComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'logout', redirectTo: 'login' },

  // Protected
  { path: 'admin', redirectTo: 'partners', pathMatch: 'full', canActivate: [AuthGuard] },

  { path: 'partners', component: PartnersComponent, canActivate: [AuthGuard] },

  {
    path: 'partners/:siteId',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      { path: '', redirectTo: 'queue', pathMatch: 'full' },
      { path: 'queue', component: QueueComponent },
      { path: 'acq', component: AcqDashboardComponent },
      { path: 'ACQ', component: AcqDashboardComponent },
      { path: 'offers-with-email', redirectTo: 'offers-with-email/user', pathMatch: 'full' },
      {
        path: 'offers-with-email',
        component: BarcodesComponent,
        children: [
          { path: 'user', component: BarcodesByUniqueIdComponent },
          { path: 'redeemed', component: BarcodesRedeemedComponent },
          { path: 'purge', component: BarcodesPurgeComponent },
          { path: 'import', component: BarcodesImportComponent },
        ]
      },
      { path: 'segments', redirectTo: 'segments/user', pathMatch: 'full' },
      {
        path: 'segments',
        component: SegmentsComponent,
        children: [
          { path: 'user', component: SegmentsByUniqueIdComponent },
          { path: 'enrolled', component: SegmentsEnrolledComponent },
          { path: 'import', component: SegmentsImportComponent },
          { path: 'purge', component: SegmentsPurgeComponent },
        ]
      },
      { path: 'dynamic-content', redirectTo: 'dynamic-content/offers', pathMatch: 'full' },
      {
        path: 'dynamic-content',
        component: DynamicContentComponent,
        children: [
          { path: 'offers', component: DynamicContentDashboardComponent },
          { path: 'import', component: DynamicContentImportComponent },
        ]
      },
      { path: 'import', redirectTo: 'upload', pathMatch: 'full' },
      { path: 'upload', component: UploadComponent },
      { path: 'gtm-opt-in', redirectTo: 'gtm-opt-in/offers', pathMatch: 'full' },
      {
        path: 'gtm-opt-in',
        component: GtmOptInSegmentsComponent,
        children: [
          { path: 'offers', component: GtmOptInComponent },
          { path: 'import', component: SegmentsImportComponent },
        ]
      },
    ]
  },
  { path: 'not-found', component: PageNotFoundComponentComponent},
  { path: '**', redirectTo: '/not-found', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    ContainersModule,
    PipesModule,
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule],
  declarations: []
})
export class RoutesModule {}
