import { Component, OnInit, OnDestroy } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { SegmentsService } from '../../services/segments.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-segments-enrolled',
  templateUrl: './segments-enrolled.component.html',
  styleUrls: ['./segments-enrolled.component.scss']
})
export class SegmentsEnrolledComponent implements OnInit, OnDestroy {

  constructor(
    private _global: GlobalService,
    private _segments: SegmentsService,
    private _dialog: MatDialog
  ) { }

  ngOnInit() { }

  ngOnDestroy() { }

  get siteId(): string {
    return this._global.siteId;
  }

  async downloadCSV(from: string, to: string) {

    const startAt = new Date(from).setHours(0, 0, 0, 0);
    const endAt = new Date(to).setHours(23, 59, 59, 59);

    return this._dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Download Enrolled Offers',
        body: `
          Are you sure you want to download enrolled offers from
          ${new Date(startAt).toLocaleString()} to ${new Date(endAt).toLocaleString()}.
        `,
        accept: () => this._segments.getEnrolledDatesAsCSV(startAt, endAt)
          .then(csv => this._global.downloadFile(csv.data, {
            filename: csv.filename,
            type: 'text/csv'
          }))
      }
    });
  }

}
