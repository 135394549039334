import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DynamicContentService } from '../../../services/dynamic-content.service';
import { DynamicContentData } from '../../../interfaces/interfaces';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from '../../../services/global.service';
import { OfferType, OfferMetaDataDoc } from '../../../models/enums';

@Component({
  selector: 'app-new-dc-offer-drag-drop',
  templateUrl: './new-dc-offer-drag-drop.component.html',
  styleUrls: ['./new-dc-offer-drag-drop.component.scss']
})
export class NewDynamicContentOfferDragDropComponent implements OnInit {

  createdOfferVars = [];
  importedListFileHeaders = [];
  importedListFileHeaderAndValue = [];
  activeIndex = 0;
  output = '';
  importData = {
    'dropLists': {},
    'sourceHeaderPositions': {},
  };
  reBefore = /(.+?)\ |/;
  reAfter = /(.?)\| (.*)/;

  postParameters =  {
      partner: undefined,
      inputFile: undefined,
      fieldMapping: undefined,
      offerName: undefined
  };

  nativeStyleHeadline = '';
  nativeStyleBody = '';
  nativeStyleOfferPeriod = '';

  hasData = false;

  constructor(
              public dynamicContentService: DynamicContentService,
              private _globalService: GlobalService,
              private _dialog: MatDialog
              ) { }

  ngOnInit() {
    this.createdOfferVars = this.dynamicContentService.getActiveVariables();

    this.dynamicContentService.dynamicOfferVarsSubmitted
      .subscribe(
        (_createdOfferVars: Array<string>) => {
          this.createdOfferVars = _createdOfferVars;
          this.createdOfferVars.forEach( (value) => {
            this.importData.dropLists[value] = new Array;
          });
          console.log(this.importData);
      });
    this.dynamicContentService.onListFileImport
      .subscribe(
        (_newOfferData: DynamicContentData) => {
          this.importedListFileHeaders = _newOfferData.importedOfferHeaders;
          this.importedListFileHeaderAndValue = _newOfferData.importedOfferMenu;
        });
      }

  sanitizeBefore(str) {
    if (!!str) {
      const valArray = this.reBefore.exec(str);
      return valArray[1];
    }
  }

  sanitizeAfter(str) {
    if (!!str) {
      const valArray = this.reAfter.exec(str);
      return valArray[2];
    }
  }

  createFieldMappings() {
    const dropItem = Object.keys(this.importData.dropLists);

    dropItem.map(key => {
      // let value = importObj[key];
      // get the value of the arrays first item
      const itemValue = this.importData.dropLists[key][0];
      // use that value and find it's index in the imported header data
      const itemIndex = this.dynamicContentService
                            .newOfferData.
                            importedOfferHeaders
                            .findIndex(val => val === this.sanitizeBefore(itemValue));
      // add to the field mapping string, don't forget to add 1 because 0 | KEY should always be zero
      this.dynamicContentService.newOfferData.fieldMappings += `,${itemIndex + 1}|${key}`;
    });
    console.log(this.dynamicContentService.newOfferData.fieldMappings);
  }

  submitClick(event) {
    this.hasData = true;
    console.log(this.importData);
    this.createFieldMappings();
    /// JUST FOR TESTING OUTPUT
    this.postParameters.fieldMapping = this.dynamicContentService.newOfferData.fieldMappings;
    this.postParameters.inputFile = this.dynamicContentService.newOfferData.listFileStorageLocation;
    this.postParameters.offerName = this.dynamicContentService.newOfferData.newOfferName;
    this.postParameters.partner = this._globalService.siteId;
    this.output = JSON.stringify(this.postParameters);
    ///
    this._dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Import Dynamic Content',
        body: `Are you sure you want to import dynamic content to <em>${this._globalService.siteId}</em>?`,
        accept: async () => {
          return this.dynamicContentService.invokeDataLoad(OfferType.DYNAMIC_OFFER, OfferMetaDataDoc.DYNAMIC_OFFER).catch((async error => {
            await console.log('job completed');
            throw new Error(error);
          }));
        },
        cancel: async () => {
          console.log('they canceled');
        }
      }
    });
  }

  dropped(event: CdkDragDrop<string[]>) {
    // this.hasData = true;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else if (event.container.data.length > 0 ) {
      // first switch out the existing item
      transferArrayItem(event.container.data,
                        event.previousContainer.data,
                        event.previousIndex,
                        event.previousContainer.data.length);
      // now drop in the new item that is dropped
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }
}
