
import { NgModule } from '@angular/core';

import { FirebaseModuleV1 } from './firebasev1.module';
import { FirebaseModuleV2 } from './firebasev2.module';

@NgModule({
  imports: [ FirebaseModuleV1, FirebaseModuleV2 ],
  exports: [ FirebaseModuleV1, FirebaseModuleV2 ]
})
export class FirebaseModule {}
