import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Authv2Service } from '../../services/authv2.service';
import { Router } from '@angular/router';
import { Credentials } from '../../interfaces';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  error: string;

  constructor(
    private _authService: AuthService,
    private _authv2Service: Authv2Service,
    private _router: Router
  ) { }

  get flash(): string {
    return this._authService.flash;
  }

  ngOnInit() { }

  handleSubmit(credentials: Credentials) {
    this._authService
    .login(credentials)
      .then(() => {
        // this._router.navigate(['/partners']);
        console.log('Auth V1 login');
        this._authv2Service
        .login(credentials)
        .then(() => {
          console.log('Auth V2 login');
          this._router.navigate(['/partners']);
        })
        .catch(error => (this.error = `login V2 error - ${error.message}`));
      })
      .catch(error => (this.error = `login V1 error - ${error.message}`));
  }


}
