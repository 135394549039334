import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { throttleTime, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { QueueType, Results, QueueIndexOn } from '../interfaces';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { AngularFireDatabaseV1, AngularFireFunctionsV1 } from '../firebase/firebasev1.module';
import { PathReference } from '@angular/fire/database/database';
import { DatabaseReference } from '@angular/fire/database/interfaces';

@Injectable()
export class QueueService {

  constructor(
    private _rtdb: AngularFireDatabaseV1,
    private _global: GlobalService,
    private _router: Router,
    private _functions: AngularFireFunctionsV1,
    private _auth: AuthService
  ) { }

  private _httpsAddNewQueueJob = this._functions.httpsCallable('callable-addNewQueueJob');

  private get _archiveQueueRef(): DatabaseReference {
    return this._rtdb.database.ref('/queue').child(this._global.siteId).child('/archive');
  }

  private get _pendingQueueRef(): DatabaseReference {
    return this._rtdb.database.ref('/queue').child(this._global.siteId).child('/pending');
  }

  private get _pendingQueueCountRef(): PathReference {
    return this._rtdb.database.ref('/queue').child(this._global.siteId).child('/pending_count');
  }

  pending(many: number = 60, value: number = Date.now() * 1000, startKey?: string): Observable<Results> {
    return this._rtdb
      .list(this._pendingQueueRef, ref => {
        return ref
          .orderByChild(QueueIndexOn.createdAt)
          .limitToLast(many + 1)
          .startAt(0)
          .endAt(value, startKey);
      })
      .snapshotChanges()
      .pipe(throttleTime(2000, undefined, { leading: true, trailing: true }))
      .pipe(tap(result => {
        if (result.length > many) {
          const next = result.shift();
          result['next'] = { key: next.key, value: next.payload.val().createdAt };
        }
      }));
  }

  archived(many: number = 60, value: number = Date.now() * 1000, startKey?: string): Observable<Results> {
    return this._rtdb
      .list(this._archiveQueueRef, ref => {
        return ref
          .orderByChild(QueueIndexOn.createdAt)
          .limitToLast(many + 1)
          .startAt(0)
          .endAt(value, startKey);
      })
      .snapshotChanges()
      .pipe(throttleTime(2000, undefined, { leading: true, trailing: true }))
      .pipe(tap(result => {
        if (result.length > many) {
          const next = result.shift();
          result['next'] = { key: next.key, value: next.payload.val().createdAt };
        }
      }));
  }

  count(): Observable<any> {
    return this._rtdb
      .object(this._pendingQueueCountRef)
      .valueChanges()
      .pipe(throttleTime(500, undefined, { leading: true, trailing: true }));
  }

  async addToPendingJobs(type: QueueType, metadata: any) {
    const { siteId } = this._global;
    const authToken  = this._auth.token;
    const user = this._auth.user;

    await this._httpsAddNewQueueJob({ type, siteId, metadata, user: { email: user.email }, authToken }).toPromise();

    this._router.navigate([`/partners/${siteId}/queue`]);
  }

  async cancel(key: string) {
    return this._pendingQueueRef.child(key).update({
      cancel: true
    });
  }

  async archive(key: string) {
    return this._pendingQueueRef.child(key).remove();
  }

  async removeArchived(key: string) {
    return this._archiveQueueRef.child(key).remove();
  }

}
