import { Component, Input, OnInit } from '@angular/core';
import { trigger,
          state,
          style,
          transition,
          animate } from '@angular/animations';
import { AnimationConfigWithData, AnimationItem } from 'lottie-web';
import gaugeAnimationConfig from './animation-ACQ-chart.json';
import { colorify } from 'lottie-colorify';

type GaugeLevelRGBMap = {
  normal: number[],
  warning: number[],
  danger: number[]
};

const gaugeColors: GaugeLevelRGBMap = {
  normal: [3, 155, 229],
  warning: [231, 204, 34],
  danger: [192, 43, 32]
};

@Component({
  selector: 'app-acq-offer-chart',
  templateUrl: './acq-offer-chart.component.html',
  styleUrls: ['./acq-offer-chart.component.scss'],
  animations: [
    trigger('introAnimBubble', [
      state('hidden', style({
        transform: 'scale(0.33)',
        opacity: 0,
        marginBottom: '-6px'
      })),
      state('shown', style({
        transform: 'scale(1)',
        opacity: 1,
        marginBottom: '*'
      })),
      transition('hidden => shown', [
        animate('.01s', style({
          opacity: 1
        })),
        animate('.25s cubic-bezier(0.33, 1, 0.68, 1)', style({
          transform: 'scale(1.05)',
          marginBottom: '*'
        })),
        animate('.15s ease-in', style({
          transform: 'scale(1)',
        })),
      ]),
      transition('shown => hidden', [
        // quick pop up
        animate('100ms', style({
          transform: 'scale(1.1)',
        })),
        // animate both face and bubble up
        animate('.2s .15s cubic-bezier(0.33, 1, 0.68, 1)', style({
          transform: 'scale(.1)',
          marginBottom: '-6px'
        })),
        // then bubble out
        style({
          opacity: 0
        })
      ]),
    ])]
})

export class AcqOfferChartComponent implements OnInit {

  @Input() percent: number;
  @Input() delay: number;

  options: Partial<AnimationConfigWithData> = {
    loop: false,
    autoplay: false,
    renderer: 'svg',
    initialSegment: [108, 109],
  };

  introPlayed = false;

  constructor() { }

  lottieChartAnim: AnimationItem;
  lottieStyles: Partial<CSSStyleDeclaration> = {
    margin: '0px',
  };

  ngOnInit(): void {
    setTimeout( () => { this.introPlayed = true; }, 250);
    this.colorifyGuage();
  }

  private colorifyGuage() {
    const rgb = gaugeColors[this.getGaugeLevel()];
    this.options.animationData = colorify([rgb], gaugeAnimationConfig); 
  }

  private getGaugeLevel(): keyof GaugeLevelRGBMap {
    if (this.showWarning)
      return 'warning';
    else if (this.showDanger)
      return 'danger';
    else
      return 'normal';
  }

  get getIntroState(): string {
    return this.introPlayed ? 'shown' : 'hidden';
  }

  get showWarning(): boolean {
    if(this.percent <= 40 && this.percent >= 16) {
      return true;
    } else {
      return false
    }
  }

  get showDanger(): boolean {
    if(this.percent <= 15) {
      return true;
    } else {
      return false;
    }
  }

  animationCreated(animationItem: AnimationItem): void {
    this.lottieChartAnim = animationItem;
    this.lottieChartAnim.setSpeed(4);
    setTimeout( () => {
      console.log('delayed call', this.percent);
      // a delay is passed down based on index
      this.lottieChartAnim.playSegments([1, this.percent]);
    }, this.delay);
  }
}
