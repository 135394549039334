import { Component, OnInit } from '@angular/core';
import Papa from 'papaparse';
import { AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { AngularFireStorageV2 } from '../../../firebase/firebasev2.module';
import { finalize } from 'rxjs/operators';
import { DynamicContentService } from '../../../services/dynamic-content.service';
import { GlobalService } from '../../../services/global.service';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';

@Component({
  selector: 'app-new-dc-offer-file-upload',
  templateUrl: './new-dc-offer-file-upload.component.html',
  styleUrls: ['./new-dc-offer-file-upload.component.scss'],
  animations: [
    trigger('fileUploadedState', [
      state('show', style({
        opacity: 1,
        height: '300px'
      })),
      state('hide', style({
        opacity: 0,
        height: '1px'
      })),
      transition('hide => show', animate('800ms ease-out', keyframes([
        style({height: '1px', offset: 0}),
        style({height: '{{importedKeyHeight}}px', offset: 0.33}),
        style({opacity: 0, offset: 0.34}),
        style({opacity: 1, offset: 1}),
      ]))),
      transition('show => hide', animate('600ms ease-out')),
    ])
  ]
})
export class NewDynamicContentOfferFileUploadComponent implements OnInit {

  file: File;
  fileName: string = undefined;
  importedHeaderWithValue = [];
  importedHeaders = [];
  importedKeysSampleData = [];
  importedKeyHeight = 0;
  itemKeyHeight = 20;

  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  uploadSnapshot: Observable<any>;
  downloadURL: string;
  fileUploaded: boolean;

  trigger: any;

  get getUploadedState(): any {
    return {
      value: this.uploadedState,
      params: {
        importedKeyHeight: this.importedKeyHeight
      }
    };
  }

  ngOnInit() {
    this.downloadURL = undefined;
    this.fileUploaded = false;
  }

  constructor( private _storage: AngularFireStorageV2,
              public dynamicContentService: DynamicContentService,
              private _globalService: GlobalService ) {}

  get uploadedState() {
    return this.fileUploaded ? 'show' : 'hide';
  }
  toggle() {
    this.fileUploaded = !this.fileUploaded;
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');
    const _file = inputNode.files[0];
    this.fileName = _file.name;

    // we want to parse the file first
    this.parseFile(_file, (importObj: Object) => {
      const keys = Object.keys(importObj);
      this.importedKeyHeight = keys.length * this.itemKeyHeight;

      keys.map(key => {
        const value = importObj[key];
        console.log(key, value);
        this.importedHeaders.push(key);
        this.importedHeaderWithValue.push(`${key} | ${value}`);
      });

      // it's expected that the first element will always be the uuid, remove that so the key is not renamed
      this.importedHeaders.shift();
      this.importedHeaderWithValue.shift();

      // push the data to the service
      this.dynamicContentService.setImportedHeaderData(this.importedHeaders, this.importedHeaderWithValue);
    });

    // then upload the file
    const filePath = `listfiles/${this._globalService.siteId}/csv/dynamic-content/${_file.name}`;
    this.task = this._storage.upload(filePath, _file);
    this.uploadProgress = this.task.percentageChanges();
    // const fileRef = this._storage.ref(`listfiles/${this._globalService.siteId}/csv/dynamic-content/${_file.name}`);
    // this.dynamicContentService.setCSVpath(`listfiles/${this._globalService.siteId}/csv/dynamic-content/${_file.name}`);
    this.task.snapshotChanges().pipe(
      finalize(async() => {
        this.fileUploaded = true;
        // const downloadURL = await fileRef.getDownloadURL().toPromise();
        // this.downloadURL = decodeURIComponent(downloadURL);
        this.dynamicContentService.setCSVpath(filePath);
      })
    )
    // .pipe(
    //   filter(data => data.metadata !== undefined)
    // )
    .subscribe(data => {
      console.log(data);
    });
  }

  parseFile(_file, _callBack) {
    Papa.parse(_file, {
      header: true,
      complete: function(results) {
        _callBack(results.data[0]);
      }
    });
  }

  continue() {
    this.dynamicContentService.setNewOfferState('dragDrop');
  }
}
