import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { map } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { ACQSegmentRef } from '../interfaces';
import { AngularFireFunctionsV1 } from '../firebase/firebasev1.module';

@Injectable()
export class ACQService {
  private _getACQcodesCount = this._functions.httpsCallable('callable-getACQoffersCodeCount');

  constructor(
    private _global: GlobalService,
    private _functions: AngularFireFunctionsV1
  ) { }

   getActiveOffers(): Observable<ACQSegmentRef[]> {
     return from(this._getACQcodesCount({siteId: this._global.siteId}).pipe(
        map(results => {
          return results.map((s: { segmentName: string; unused_codes_count: number; total_imported_codes: number; }) => (
            { segmentId: s.segmentName,
              unused_codes_count: s.unused_codes_count,
              total_imported_codes: s.total_imported_codes }))
        })
      ).toPromise());
   }
}
