import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { DynamicContentService } from '../../../services/dynamic-content.service';
import { map } from 'rxjs/operators';
import { merge, Subscription, Observable } from 'rxjs';
import { GlobalService } from '../../../services/global.service';
import { Router } from '@angular/router';
import { DataflowStatus } from '../../../interfaces/interfaces';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MatExpansionPanel } from '@angular/material/expansion';
import { OfferType, OfferMetaDataDoc } from '../../../models/enums';
import * as moment from 'moment';
@Component({
  selector: 'app-dynamic-content-offers',
  templateUrl: './dynamic-content-offers.component.html',
  styleUrls: ['./dynamic-content-offers.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('offersDisplayState', [
      state('show', style({
        opacity: 1
      })),
      state('hide', style({
        opacity: 0
      })),
      transition('* => *', animate('1200ms ease-out')),
    ])
  ],
  viewProviders: [MatExpansionPanel],
})
export class DynamicContentOffersComponent implements OnDestroy, OnInit {

  @Input() offerType: string;
  documentIDs: Array<string>;
  documentData: Array<any>;
  siteId: string;
  hasOffers = false;
  offersSnapshot: Observable<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>;
  offerValues: Observable<any>;
  offerTitle: string;
  isDynamicOffer = false;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private dynamicContentService: DynamicContentService,
    private _globalService: GlobalService,
    private _router: Router,
    private _dialog: MatDialog
    ) { }

  private subscriptions: Subscription[] = [];
  offerDataflowStatus: DataflowStatus[] = [];
  private _offerType: string;
  private _offerMetaDoc: string;

  get hasOffersState() {
    return this.hasOffers ? 'show' : 'hide';
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit() {
    if (this.offerType === OfferType.SPECIAL_OFFER) {
      this._offerType = OfferType.SPECIAL_OFFER;
      this._offerMetaDoc = OfferMetaDataDoc.SPECIAL_OFFER;
      this.offerTitle = 'Active GTM Opt-In Segments';
      this.offersSnapshot = this.dynamicContentService.specialOffersDocumentSnapshot;
      this.offerValues = this.dynamicContentService.specialOffersValues;
    } else {
      this.isDynamicOffer = true;
      this._offerType = OfferType.DYNAMIC_OFFER;
      this._offerMetaDoc = OfferMetaDataDoc.DYNAMIC_OFFER;
      this.offerTitle = 'Active Dynamic Content';
      this.offersSnapshot = this.dynamicContentService.dynamicOffersDocumentSnapshot;
      this.offerValues = this.dynamicContentService.dynamicOffersValues;
    }
    this.subscriptions.push(
      merge(
        this.offersSnapshot
          .pipe(
            map( querySnapshot => querySnapshot.docs.map( docSnapshot => docSnapshot.id ))
          ),
        this.offerValues
          .pipe(
            map(offers => {
             
              return offers.map(offerData => {
                let createdAt = offerData.createdAt;

                if (typeof createdAt === 'number') {
                  createdAt = moment(createdAt).format('MM/DD/YYYY hh:mm A');
                }
               
                return {
                  createdAt: createdAt,
                  createdBy: offerData.createdBy.email,
                  fields: offerData.fields,
                  listFile: offerData.listFile,
                  dataflowJobId: offerData.dataflowJobId,
                  dataflowJobStatus: offerData.dataflowJobStatus,
                  _insertID: offerData._insertID,
                  dataflowOfferFieldMapping: offerData.dataflowFieldMapping,
                  dataflowOfferType: offerData.dataflowOfferType,
                  recordsInserted: offerData.recordsInserted || 0,
                  totalRows: offerData.totalRows || 0
                };
              });
            })
          )
      ).subscribe( data => this.onDataUpdate(data) )
    );
    this.siteId = this._globalService.siteId;
  }

  onDataUpdate(data: string[] | any[]) {
    const propertyToUpdate = typeof data[0] === 'string' ? 'documentIDs' : 'documentData';
    this[propertyToUpdate] = data;
    this[propertyToUpdate].length >= 1 ? this.hasOffers = true : this.hasOffers = false;
    // loop over
    for (let i = 0; i < this.documentData.length; i++) {
      const _offerFormSubmittedSuccessfully = this.documentData[i].listFile === 'no-value' ? false : true;
      const _status = this.documentData[i].dataflowJobStatus !== 'JOB_STATE_DONE' && this.documentData[i].dataflowJobStatus !== 'JOB_STATE_CANCELLED' ? false : true;
      const _failed = this.documentData[i].dataflowJobStatus === 'JOB_STATE_FAILED' ? true : false;
      const statusObj: DataflowStatus = {
        statusComplete: _status,
        offerFormSubmittedSuccessfully: _offerFormSubmittedSuccessfully,
        statusFailed: _failed,
        showNotAvailableInfo: false
      };
      this.offerDataflowStatus[i] = statusObj;
    }
    // keep this array in sync, input form will test agains currentOfferNames
    this.dynamicContentService.currentOfferNames = this.documentIDs;
    this.changeDetector.markForCheck();
  }

  createNewOfferWithVars(offerVars: Array<string>) {
    this.dynamicContentService.newOfferData.createdOfferVariables = offerVars;

    this._router.navigate([`/partners/${this.siteId}/dynamic-content/import`]);
  }

  createNewOffer() {
    this.dynamicContentService.clearNewOfferData();

    // need to check for special offer or dynamic offer
    const routerLink = this.isDynamicOffer ? `/partners/${this.siteId}/dynamic-content/import` : `/partners/${this.siteId}/segments/import`;
    this._router.navigate([routerLink]);
  }

  showDataflowDelete(status: DataflowStatus) {
    if (status.statusComplete === true || status.statusFailed === true) {
      return true;
    } else {
      return false;
    }
  }

  getJobStatus(id: string, offerName: string) {
    this.dynamicContentService.updateDataFlowStatus(id, offerName, this._offerMetaDoc).catch(async error => {
      console.log(error);
      throw new Error(error);
    });
  }

  deleteOffer(offerName: string, offerData: any) {
    this._dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Dynamic Offer',
        body: `Are you sure you want to delete offer <em>${offerName} from ${this._globalService.siteId}</em>?`,
        accept: async () => {
          return this.dynamicContentService.deleteDynamicOffer(offerName,
                                                                offerData,
                                                                this._offerType,
                                                                this._offerMetaDoc)
                                                                .catch((async error => {
            console.log('job completed');
            throw new Error(error);
          }));
        },
        cancel: async () => {
          console.log('they canceled');
        }
      }
    });
  }

  deleteOfferMetadata(offerName: string) {
    this._dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Dynamic Offer',
        body: `Are you sure you want to delete offer <em>${offerName} from ${this._globalService.siteId}</em>?`,
        accept: async () => {
          return this.dynamicContentService.deleteDynamicOfferMetadata(offerName, this._offerMetaDoc).catch((async error => {
            console.log('job completed');
            throw new Error(error);
          }));
        },
        cancel: async () => {
          console.log('they canceled');
        }
      }
    });
  }

}
