// specifies what collection the offers are stored, used by dataflow
export enum OfferType {
  DYNAMIC_OFFER = 'dynamicOfferContent',
  SPECIAL_OFFER = 'specialOffers'
}

// specifies what document offer metadata is located in
export enum OfferMetaDataDoc {
  DYNAMIC_OFFER = 'dynamicOffers',
  SPECIAL_OFFER = 'specialOffers'
}
