import { Component, Input, OnInit } from '@angular/core';
import { ACQSegmentRef } from '../../interfaces';

@Component({
  selector: 'app-acq-offer-info',
  templateUrl: './acq-offer-info.component.html',
  styleUrls: ['./acq-offer-info.component.scss']
})
export class AcqOfferInfoComponent implements OnInit {

  @Input() offerData: ACQSegmentRef;
  @Input() index: number;

  constructor() {}

  ngOnInit(): void {
    
  }

  get percentOfUnusedOffers(): number {
    return Math.floor((this.offerData.unused_codes_count / this.offerData.total_imported_codes) * 100);
  }

  get delay(): number {
    // (index * delay for each) + base delay
    return (this.index * 250) + 750;
  }
}
