import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { DynamicContentService } from '../../../services/dynamic-content.service';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { GlobalService } from '../../../services/global.service';
import { OfferType, OfferMetaDataDoc } from '../../../models/enums';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-new-dc-offer-form',
  templateUrl: './new-dc-offer-form.component.html',
  styleUrls: ['./new-dc-offer-form.component.scss']
})
export class NewDynamicContentOfferFormComponent implements OnInit {

  @ViewChild('offerNameInput', {static: false}) offerNameInput: ElementRef;
  @ViewChild('newVarInput', {static: false}) newVarInput: ElementRef;
  @ViewChild('newOfferAddVarButton', {static: false}) newVarButton: ElementRef;

  constructor(
              private dynamicContentService: DynamicContentService,
              private _globalService: GlobalService,
              private _dialog: MatDialog
              ) { }

  newOfferName: string;
  newOfferVars = [];
  btnNextDisabled = true;
  matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  validOfferName = new FormControl(
                        '',
                        [Validators.required,
                        Validators.pattern('^[a-zA-Z0-9-_]*$'),
                        this.duplicateValidatorOfferNames.bind(this)]);

  validVar = new FormControl('',
                        [Validators.required,
                        Validators.pattern('^[a-zA-Z0-9-_]*$'),
                        this.duplicateValidatorVars.bind(this)]);

  duplicateValidatorVars(control: FormControl) {
    const inputName = control.value;
    const match = this.dynamicContentService.newOfferData.createdOfferVariables.includes(inputName);
    if (match) {
      return {
        errorObj: {
          duplicateName: inputName
        }
      };
    }
    return null;
  }
  duplicateValidatorOfferNames(control: FormControl) {
    const inputName = control.value;
    if (this.dynamicContentService.currentOfferNames === undefined || this.dynamicContentService.currentOfferNames.length === 0) {
      return null;
    }
    const match = this.dynamicContentService.currentOfferNames.includes(inputName);
    if (match) {
      return {
        errorObj: {
          duplicateName: inputName
        }
      };
    }
    return null;
  }

  getOfferNameErrorMessage() {
    return this.validOfferName.hasError('required') ? 'You must enter a value' :
        this.validOfferName.hasError('pattern') ? 'Not a valid Offer Name, no spaces or special characters' :
        this.validOfferName.errors.errorObj.duplicateName ?
          `${this.validOfferName.errors.errorObj.duplicateName} is already in use for another offer` : '';
  }

  getVarInputErrorMessage() {
    return this.validVar.errors.required ? 'You must enter a value' :
        this.validVar.hasError('pattern') ? 'Not a valid variable, no spaces or special characters' :
        this.validVar.errors.errorObj.duplicateName ?
          `${this.validVar.errors.errorObj.duplicateName} is a duplicate, please define a unique variable` : '';
  }

  ngOnInit() {
    this.newOfferVars = this.dynamicContentService.getActiveVariables();

    this.dynamicContentService.dynamicOfferVarsChanged
      .subscribe(
        (activeVariables: Array<string>) => {
          this.newOfferVars = activeVariables;
        }
      );
  }

  setOfferName() {
    if (this.validOfferName.status !== 'INVALID' && this.validVar.status !== '') {
      this.newOfferName = this.offerNameInput.nativeElement.value;
      this.dynamicContentService.setOfferName(this.newOfferName);
      this.checkForContinue();
    }
  }

  checkForContinue() {
    console.log(this.newOfferVars.length);
    if (this.newOfferName !== '' && this.newOfferVars.length >= 1) {
      this.btnNextDisabled = false;
    } else {
      this.btnNextDisabled = true;
    }
  }

  addDynamicVar() {
    this.validVar.updateValueAndValidity();
    console.log(this.validVar.status);
    // variable form validation needed
    if (this.validVar.status !== 'INVALID' && this.validVar.status !== '') {
      this.dynamicContentService.addNewOfferVar(this.newVarInput.nativeElement.value);
      // reset input
      this.newVarInput.nativeElement.value = '';
      this.checkForContinue();
    } else {
      // change focus to see error state
      this.getVarInputErrorMessage();
    }
  }

  removeDynamicVar(id: string) {
    console.log(id);
    this.dynamicContentService.removeOfferVar(id);
  }

  addDynamicContentVar(id: string) {
    this.dynamicContentService.addNewOfferVar(id);
  }

  continue() {
    if (this.newOfferVars.length !== 0 ) {
      // check to make sure the name has been set
      if (this.dynamicContentService.newOfferData.newOfferName === undefined
        && this.dynamicContentService.newOfferData.newOfferName !== '') {
        this.dynamicContentService.newOfferData.newOfferName = this.newOfferName;
      }
      this._dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Create New Dynamic Offer',
          body: `Are you sure you want to create offer <em>${this.dynamicContentService.newOfferData.newOfferName}</em>
                 for <em>${this._globalService.siteId}</em>?<p>
                 Variables are not editable after clicking continue.</p>`,
          accept: async () => {
            return this.dynamicContentService
                        .initNewOfferMetaData(OfferType.DYNAMIC_OFFER,
                                              OfferMetaDataDoc.DYNAMIC_OFFER)
                                              .catch((async error => {
              await console.log('job completed');
              throw new Error(error);
            }));
          },
          cancel: async () => {
            console.log('they canceled');
          }
        }
      });
    }
  }
}
