import { Component, OnInit, Input } from '@angular/core';
import { AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { filter, finalize, tap } from 'rxjs/operators';
import { AngularFireStorageV2 } from '../../firebase/firebasev2.module';
import { UploadTaskSnapshot } from '@angular/fire/storage/interfaces';

@Component({
  selector: 'upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements OnInit {
  @Input() file: File;

  task: AngularFireUploadTask;

  percentage: Observable<number>;
  snapshot: Observable<UploadTaskSnapshot>;
  downloadURL: string;

  constructor(private storage: AngularFireStorageV2) { }
   
  ngOnInit() {
    this.startUpload();
  }

  startUpload() {
    const path = `test/${this.file.name}`;
    const ref = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, this.file);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();
   
    this.snapshot   = this.task.snapshotChanges().pipe(
      tap(console.log),
      // The file's download URL
      finalize(async() =>  {
        this.downloadURL = await ref.getDownloadURL().toPromise();
        return this.task.snapshotChanges().pipe(
          filter((task) => task.state === "RUNNING")
        )
        // this.db.collection('files').add( { downloadURL: this.downloadURL, path });
      }),
    );
  }

  isActive(snapshot: UploadTaskSnapshot): boolean {

    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }
}
