import { Component, OnInit } from '@angular/core';
import { AngularFireStorageV2 } from '../../firebase/firebasev2.module';
import { DynamicContentService } from '../../services/dynamic-content.service';

@Component({
  selector: 'app-dynamic-content-import',
  templateUrl: './dynamic-content-import.component.html',
  styleUrls: ['./dynamic-content-import.component.scss']
})
export class DynamicContentImportComponent implements OnInit {

  constructor(storage: AngularFireStorageV2,
              private dynamicContentService: DynamicContentService) {
    console.log(storage);
  }

  step1 = true; // define offer variables
  step2 = false; // upload file + pull headers
  step3 = false; // pair variables

  newOfferState: string;

  ngOnInit() {
    this.newOfferState = this.dynamicContentService.getNewOfferState();

    this.dynamicContentService.onNewOfferStateUpdate
      .subscribe(
        (state: string) => {
          this.newOfferState = state;
          switch (state) {
            case 'form':
              this.step1 = true;
              this.step2 = false;
              this.step3 = false;
              break;
            case 'upload':
              this.step1 = false;
              this.step2 = true;
              this.step3 = false;
              break;
            case 'dragDrop':
              this.step1 = false;
              this.step2 = false;
              this.step3 = true;
              break;
          }
          console.log(this.step1);
        }
      );
  }

}
