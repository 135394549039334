import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyJSON'
})
export class PrettyJSONPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return JSON ? JSON.stringify(value, null, 2) : 'your browser doesnt support JSON so cant pretty print';
  }

}
