import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeysPipe } from './keys.pipe';
import { DatePipe } from './date.pipe';
import { IsItExpiredPipe } from './is-it-expired.pipe';
import { EncodeUriPipe } from './encode-uri.pipe';
import { DecodeURIPipe } from './decode-uri.pipe';
import { TimeStampPipe } from './time-stamp.pipe';
import { PrettyJSONPipe } from './pretty-json.pipe';
import { ReversePipe } from './reverse.pipe';
import { PrettierJSONPipe } from './prettier-json.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { DurationPipe } from './duration.pipe';
import { FileSizePipePipe } from './file-size-pipe.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    KeysPipe,
    DatePipe,
    IsItExpiredPipe,
    EncodeUriPipe,
    DecodeURIPipe,
    TimeStampPipe,
    PrettyJSONPipe,
    ReversePipe,
    PrettierJSONPipe,
    TimeAgoPipe,
    DurationPipe,
    FileSizePipePipe
  ],
  declarations : [
    KeysPipe,
    DatePipe,
    IsItExpiredPipe,
    EncodeUriPipe,
    DecodeURIPipe,
    TimeStampPipe,
    PrettyJSONPipe,
    ReversePipe,
    PrettierJSONPipe,
    TimeAgoPipe,
    DurationPipe,
    FileSizePipePipe
  ]
})
export class PipesModule { }
