import { Injectable, EventEmitter } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { Authv2Service } from '../services/authv2.service';
import { AngularFireFunctionsV2, AngularFirestoreV2 } from '../firebase/firebasev2.module';
import { DynamicContentData } from '../interfaces/interfaces';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Router, ResolveEnd } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { OfferMetaDataDoc, OfferType } from '../models/enums';

@Injectable({
  providedIn: 'root'
})

export class DynamicContentService {

  dynamicOfferVarsChanged = new EventEmitter<string[]>();
  dynamicOfferVarsSubmitted = new EventEmitter<string[]>();
  onListFileImport = new EventEmitter<DynamicContentData>();
  onNewOfferStateUpdate = new EventEmitter<string>();
  dynamicOffersDocumentSnapshot: Observable<firebase.firestore.QuerySnapshot>;
  dynamicOffersValues: Observable<any>;
  specialOffersDocumentSnapshot: Observable<firebase.firestore.QuerySnapshot>;
  specialOffersValues: Observable<any>;

  private newOfferState: string;

  newOfferData: DynamicContentData = {
    newOfferName: undefined,
    listFileStorageLocation: undefined,
    createdOfferVariables: [],
    importedOfferMenu: [],
    importedOfferHeaders: [],
    fieldMappings: '0|KEY'
  };

  currentOfferNames: Array<string> = [];

  constructor(private _functions: AngularFireFunctionsV2,
              private _database: AngularFirestoreV2,
              private _global: GlobalService,
              private _auth: Authv2Service,
              private _router: Router) {
                _router.events.subscribe((event) => {
                  if (event instanceof ResolveEnd) {
                    this.setSnapshots();
                  }
                });
                this.setSnapshots();
              }

  private _callableInitNewOfferMetadata = this._functions.httpsCallable('initNewOfferMetadata');
  private _callableDataLoadJob = this._functions.httpsCallable('invokeDataLoadJob');
  private _callableCheckDataflowJobStatus = this._functions.httpsCallable('checkDataflowJobStatus');
  private _callableDeleteDynamicOffer = this._functions.httpsCallable('deleteDynamicOffer');
  private _callableDeleteDynamicOfferMetadata = this._functions.httpsCallable('deleteDynamicOfferMetadata');

  setSnapshots() {
    this.dynamicOffersDocumentSnapshot =
      this._database.collection(`${this._global.siteId}/_metadata/${OfferMetaDataDoc.DYNAMIC_OFFER}`)
        .snapshotChanges()
        .pipe(
          mergeMap(() => this._database.collection(`${this._global.siteId}/_metadata/${OfferMetaDataDoc.DYNAMIC_OFFER}`).get())
        );
    this.dynamicOffersValues =
        this._database.collection(`${this._global.siteId}/_metadata/${OfferMetaDataDoc.DYNAMIC_OFFER}`)
        .valueChanges();

    this.specialOffersDocumentSnapshot =
      this._database.collection(`${this._global.siteId}/_metadata/${OfferMetaDataDoc.SPECIAL_OFFER}`)
        .snapshotChanges()
        .pipe(
          mergeMap(() => this._database
                          .collection(`${this._global.siteId}/_metadata/${OfferMetaDataDoc.SPECIAL_OFFER}`)
                          .get())
        );
    this.specialOffersValues = this._database
                                .collection(`${this._global.siteId}/_metadata/${OfferMetaDataDoc.SPECIAL_OFFER}`)
                                .valueChanges();
  }

  clearNewOfferData() {
    this.newOfferData = {
      newOfferName: undefined,
      listFileStorageLocation: undefined,
      createdOfferVariables: [],
      importedOfferMenu: [],
      importedOfferHeaders: [],
      fieldMappings: '0|KEY'
    };
  }
  setOfferName(id: string) {
    this.newOfferData.newOfferName = id;
  }

  testData() {
    console.log(this._database);
  }

  setCSVpath(path: string) {
    const pathWithBucket = `gs://${environment.specialOffers.storageBucket}/${path}`;
    this.newOfferData.listFileStorageLocation = pathWithBucket;
  }

  getOfferName() {
    return this.newOfferData.newOfferName;
  }

  getActiveVariables() {
    return this.newOfferData.createdOfferVariables.slice();
  }

  setImportedHeaderData(listFileHeaders: Array<string>, pairedData:  Array<string>) {
    console.log('import header data');
    console.log(this.newOfferData );
    this.newOfferData.importedOfferMenu = pairedData;
    this.newOfferData.importedOfferHeaders = listFileHeaders;
    this.onListFileImport.emit( this.newOfferData );
  }

  addNewOfferVar(id: string) {
    this.newOfferData.createdOfferVariables.push(id);
    this.dynamicOfferVarsChanged.emit( this.newOfferData.createdOfferVariables.slice() );
  }

  removeOfferVar(id: string) {
    for (let i = 0; i < this.newOfferData.createdOfferVariables.length; i++) {
      if ( this.newOfferData.createdOfferVariables[i] === id) {
        this.newOfferData.createdOfferVariables.splice(i, 1);
        this.dynamicOfferVarsChanged.emit( this.newOfferData.createdOfferVariables.slice() );
      }
    }
  }

  setNewOfferState(step: string) {
    if ( step === 'form' || step === 'upload' || step === 'dragDrop' || step === 'complete') {
      this.newOfferState = step;
      this.onNewOfferStateUpdate.emit( this.newOfferState.valueOf() );
      this.dynamicOfferVarsSubmitted.emit( this.newOfferData.createdOfferVariables.slice() );
    }
  }

  getNewOfferState() {
    return this.newOfferState;
  }

  async initNewOfferMetaData(_offerType: string, _offerMetaDoc: string) {
    const { siteId } = this._global;
    const authToken  = this._auth.token;
    const user = this._auth.user;
    const params = {
      partner: siteId,
      fields: this.newOfferData.createdOfferVariables,
      offerName: this.newOfferData.newOfferName,
      offerType: _offerType,
      metaDataDoc: _offerMetaDoc
    };
    await this._callableInitNewOfferMetadata({ params, user: { email: user.email }, authToken }).toPromise();

    this.setNewOfferState('upload');
  }

  async invokeDataLoad(_offerType: string, _offerMetaDoc: string) {
    const { siteId } = this._global;
    const authToken  = this._auth.token;
    const user = this._auth.user;

    const params = {
      partner: siteId,
      inputFile: this.newOfferData.listFileStorageLocation,
      fieldMapping: this.newOfferData.fieldMappings,
      offerName: this.newOfferData.newOfferName,
      offerType: _offerType,
      metaDataDoc: _offerMetaDoc
    };

    await this._callableDataLoadJob({ params, user: { email: user.email }, authToken }).toPromise();

    this.clearNewOfferData();
    // check where in the UI to send
    if (_offerType === OfferType.DYNAMIC_OFFER) {
      this._router.navigate([`/partners/${siteId}/dynamic-content/offers`]);
    } else {
      this._router.navigate([`/partners/${siteId}/queue`]);
    }
  }

  async updateDataFlowStatus(dataflowJobId: string, offerName: string, metaDataDoc: string) {
    const { siteId } = this._global;
    const authToken  = this._auth.token;
    const user = this._auth.user;
    const params = {
      partner: siteId,
      dataflowJobId,
      offerName,
      metaDataDoc
    };

    await this._callableCheckDataflowJobStatus({ params, user: { email: user.email }, authToken }).toPromise();
  }

  async deleteDynamicOffer(_offerName: string, _offerMetaData: any, _offerType: string, _offerMetaDoc: string) {
    const { siteId } = this._global;
    const authToken  = this._auth.token;
    const user = this._auth.user;
    const params = {
      partner: siteId,
      inputFile: _offerMetaData.listFile,
      fieldMapping: _offerMetaData.dataflowOfferFieldMapping,
      offerName: _offerName,
      offerType: _offerType,
      _insertID: _offerMetaData._insertID,
      metaDataDoc: _offerMetaDoc
    };

    await this._callableDeleteDynamicOffer({ params, user: { email: user.email }, authToken }).toPromise();

    console.log('cloud function delete called');
  }

  async deleteDynamicOfferMetadata(_offerName: string, _offerMetaDoc: string, ) {
    const { siteId } = this._global;
    const authToken  = this._auth.token;
    const user = this._auth.user;
    const params = {
      partner: siteId,
      offerName: _offerName,
      metaDataDoc: _offerMetaDoc
    };

    await this._callableDeleteDynamicOfferMetadata({ params, user: { email: user.email }, authToken }).toPromise();

    console.log('cloud function delete called');
  }
}
