import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Results, QueueType } from '../../interfaces';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-queue-jobs-list',
  templateUrl: './queue-jobs-list.component.html',
  styleUrls: ['./queue-jobs-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [MatExpansionPanel],
})
export class QueueJobsListComponent implements OnInit {

  @Input() jobs: Results[];

  @Input() actions: boolean;

  @Output() archive = new EventEmitter<string>();

  @Output() cancel =  new EventEmitter<string>();

  types = QueueType;

  constructor(
    private _dialog: MatDialog
  ) { }

  ngOnInit() { }

  trackBy(index, item) {
    return item.key;
  }

  triggerArchive(key: string) {
    this.archive.emit(key);
  }

  triggerCancel(key: string) {
    this.cancel.emit(key);
  }

  openDialogError(error: string) {
    this._dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Server Side Error',
        body: error
      }
    });
  }

}
