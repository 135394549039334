import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Payload, SegmentRef } from '../../interfaces';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-segments-list',
  templateUrl: './segments-list.component.html',
  styleUrls: ['./segments-list.component.css'],
  viewProviders: [MatExpansionPanel],
})
export class SegmentsListComponent implements OnInit {

  @Input() segments: Payload<SegmentRef>[] = [];

  @Output() updated = new EventEmitter<Payload<SegmentRef>>();

  @Output() removed = new EventEmitter<Payload<SegmentRef>>();

  constructor() { }

  ngOnInit() { }

  trackBy(_index: number, item: Payload<SegmentRef>) {
    return item.key;
  }

  update(payload: Payload<SegmentRef>) {
    this.updated.emit(payload);
  }

  remove(payload: Payload<SegmentRef>) {
    this.removed.emit(payload);
  }

}
