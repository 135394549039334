import { Component, OnInit } from '@angular/core';
import { OfferType } from '../../models/enums';

@Component({
  selector: 'app-dynamic-content-dashboard',
  templateUrl: './dynamic-content-dashboard.component.html',
  styleUrls: ['./dynamic-content-dashboard.component.scss']
})
export class DynamicContentDashboardComponent implements OnInit {

  DYNAMIC_OFFERS = OfferType.DYNAMIC_OFFER;
  SPECIAL_OFFERS = OfferType.SPECIAL_OFFER;

  constructor() {}

  ngOnInit() {

  }

}
