import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ACQSegmentRef } from '../../interfaces';
import { ACQService } from '../../services/acq.service';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-acq-dashboard',
  templateUrl: './acq-dashboard.component.html',
  styleUrls: ['./acq-dashboard.component.scss']
})
export class AcqDashboardComponent implements OnInit {
  activeOffers: Observable<ACQSegmentRef[]>;
  offersHidden = false;

  constructor(
    private _global: GlobalService,
    private _ACQService:ACQService) {
    this._global.title = 'ACQ Offers';
  }

  ngOnInit(): void {
   this.activeOffers = this._ACQService.getActiveOffers();
  }

  toggleHideOffers() {
    this.offersHidden = !this.offersHidden;
  }

}
