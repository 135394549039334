import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { AngularFireAction } from '@angular/fire/database';
import { DataSnapshot } from '@firebase/database';
import { Payload, BarcodeRef } from '../../interfaces';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-barcodes-list',
  templateUrl: './barcodes-list.component.html',
  styleUrls: ['./barcodes-list.component.css'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [MatExpansionPanel],
})
export class BarcodesListComponent implements OnInit {

  @Input() results: AngularFireAction<DataSnapshot>[] = [];

  @Output() updated = new EventEmitter<Payload<BarcodeRef>>();

  @Output() removed = new EventEmitter<Payload<BarcodeRef>>();

  constructor() { }

  ngOnInit() { }

  trackBy(index, item) {
    return item.key;
  }

  update(payload: Payload<BarcodeRef>) {
    this.updated.emit(payload);
  }

  remove(payload: Payload<BarcodeRef>) {
    this.removed.emit(payload);
  }

}
