// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  specialOffers: {
    apiKey: 'AIzaSyA2NmOXRejlEXUOeCMdhg_g7tiLk3quDso',
    authDomain: 'specialoffers-dmar-dev.firebaseapp.com',
    databaseURL: 'https://specialoffers-dmar-dev.firebaseio.com',
    projectId: 'specialoffers-dmar-dev',
    storageBucket: 'special-offers-dev',
    messagingSenderId: '489216680480',
    appId: '1:489216680480:web:a90d4a9977955d68bbd245'
  },
  firebase: {
    apiKey: 'AIzaSyAs_Fa5jCmJqda2os6olFM_zzLcHasGsUI',
    authDomain: 'citi-retail-list-file-dev.firebaseapp.com',
    databaseURL: 'https://citi-retail-list-file-dev.firebaseio.com',
    projectId: 'citi-retail-list-file-dev',
    storageBucket: 'citi-retail-list-file-dev.appspot.com',
    messagingSenderId: '161160450885'
  },
  endpoints: {
    howdy: 'http://35.224.4.249/howdy'
  },
  functionsRegion: 'us-central1'
};
