import { Component, OnInit, OnDestroy } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { QueueType } from '../../interfaces';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { QueueService } from '../../services/queue.service';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AngularFireDatabaseV1 } from '../../firebase/firebasev1.module';

@Component({
  selector: 'app-segments-purge',
  templateUrl: './segments-purge.component.html',
  styleUrls: ['./segments-purge.component.scss']
})
export class SegmentsPurgeComponent implements OnInit, OnDestroy {

  private _alive = false;
  private _segments: Subscription;

  segments: string[] = [];
  enrollmentWithGTM = true;

  constructor(
    private _global: GlobalService,
    private _dialog: MatDialog,
    private _queue: QueueService,
    private _rtdb: AngularFireDatabaseV1
  ) {
    this._segments = this._rtdb.list(this._global.partnerRootRef.child('/__segments'))
      .snapshotChanges()
      .pipe(takeWhile(() => this._alive))
      .subscribe(segments => {
        this.segments = segments.map(x => x.key);
      });
  }

  get siteId(): string {
    return this._global.siteId;
  }

  toggleGTMenrollment() {
    this.enrollmentWithGTM = !this.enrollmentWithGTM;
  }

  ngOnInit() {
    this._alive = true;
  }

  ngOnDestroy() {
    this._global.loading = false;
    this._alive = false;
    this._segments.unsubscribe();
  }

  purge(segmentId: string) {
    this._dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Purge Segment Offers',
        body: `Are you sure you want to delete all ${segmentId} segment offers?`,
        accept: async () => {
          return this._queue.addToPendingJobs(QueueType.segmentPurge, {
            segmentId
          });
        }
      }
    });
  }

}
