import { Component, ViewEncapsulation } from '@angular/core';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-barcodes',
  templateUrl: './barcodes.component.html',
  styleUrls: ['./barcodes.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BarcodesComponent {

  constructor(private _global: GlobalService) {
    this._global.title = 'Barcodes';
  }

}
