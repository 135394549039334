import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuthV1 } from '../firebase/firebasev1.module';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _firebaseAuth: AngularFireAuthV1,
  ) {
    this._firebaseAuth.authState.subscribe(user => {
      if (!user) {
        this._router.navigate(['/login']);
      }
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._firebaseAuth.authState.pipe(
      take(1),
      map(authState => !!authState)
    );
  }
}
