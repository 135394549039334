import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Authv2Service } from '../../services/authv2.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  constructor(
    private _authService: AuthService,
    private _authv2Service: Authv2Service,
    private _router: Router
  ) { }

  error: string;

  ngOnInit() { }

  handleSubmit(email: string) {
    this._authv2Service
      .reset(email)
      .then(() => {
        this._authService
          .reset(email)
          .then(() => {
            this._router.navigate(['/login']);
          })
          .catch(error => (this.error = error.message));
      })
      .catch(error => (this.error = error.message));
  }

}
