import { Component, ViewEncapsulation } from '@angular/core';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-dynamic-content',
  templateUrl: './dynamic-content.component.html',
  styleUrls: ['./dynamic-content.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DynamicContentComponent {

  constructor(private _global: GlobalService) {
    this._global.title = 'dynamic-content';
  }

}
