import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions, REGION, ORIGIN } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore  } from '@angular/fire/firestore';
import { environment } from '../../environments/environment';
import { FirebaseApps } from '../interfaces';
import { NgModule, Injectable, NgZone, PLATFORM_ID } from '@angular/core';

const v2AppName = FirebaseApps.V2;
const v2AppOptions = environment[v2AppName];
const functionsRegion = environment.functionsRegion;
// const functionsOrigin = 'https://api-v2.dmar-specialoffers.com';
const functionsOrigin = null;

@Injectable()
export class AngularFireAuthV2 extends AngularFireAuth { }

export function angularFireAuthV2Factory(platformId: Object, zone: NgZone): AngularFireAuthV2 {
  return new AngularFireAuthV2(v2AppOptions, v2AppName, platformId, zone);
}

@Injectable()
export class AngularFireFunctionsV2 extends AngularFireFunctions { }

export function angularFireFunctionsV2Factory(platformId: Object, zone: NgZone): AngularFireFunctionsV2 {
  return new AngularFireFunctionsV2(v2AppOptions, v2AppName, zone, functionsRegion, functionsOrigin);
}

@Injectable()
export class AngularFireStorageV2 extends AngularFireStorage { }

export function angularFireStorageV2Factory(platformId: Object, zone: NgZone): AngularFireStorageV2 {
  const { storageBucket } = v2AppOptions;
  return new AngularFireStorageV2(v2AppOptions, v2AppName, storageBucket, platformId, zone);
}

@Injectable()
export class AngularFirestoreV2 extends AngularFirestore { }

export function angularFirestoreV2Factory(platformId: Object, zone: NgZone): AngularFirestoreV2 {
  return new AngularFirestoreV2(v2AppOptions, v2AppName, false, {}, platformId, zone, {});
}

@NgModule({
  providers: [
    {
      provide: ORIGIN,
      useValue: functionsOrigin,
    },
    {
      deps: [PLATFORM_ID, NgZone],
      provide: AngularFireAuthV2,
      useFactory: angularFireAuthV2Factory,
    },
    {
      deps: [PLATFORM_ID, NgZone],
      provide: AngularFireFunctionsV2,
      useFactory: angularFireFunctionsV2Factory,
    },
    {
      deps: [PLATFORM_ID, NgZone],
      provide: AngularFireStorageV2,
      useFactory: angularFireStorageV2Factory,
    },
    {
      deps: [PLATFORM_ID, NgZone],
      provide: AngularFirestoreV2,
      useFactory: angularFirestoreV2Factory,
    },
    {
      provide: REGION,
      useValue: functionsRegion
    }
  ]
})
export class FirebaseModuleV2 { }
