import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { RoutesModule } from './routes/routes.module';
import { AppComponent } from './app.component';
import 'rxjs/operator/switchMap';
import 'rxjs/observable/of';
import { PageNotFoundComponentComponent } from './page-not-found-component/page-not-found-component.component';
import { ServicesModule } from './services/services.module';

@NgModule({
  declarations: [AppComponent, PageNotFoundComponentComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RoutesModule,
    ServicesModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-US' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
