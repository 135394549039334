import { Component, OnDestroy } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { Payload, SegmentRef } from '../../interfaces';
import { SegmentsService } from '../../services/segments.service';
import { AngularFireFunctionsV1 } from '../../firebase/firebasev1.module';

@Component({
  selector: 'app-segments-by-unique-id',
  templateUrl: './segments-by-unique-id.component.html',
  styleUrls: ['./segments-by-unique-id.component.scss']
})
export class SegmentsByUniqueIdComponent implements OnDestroy {

  private _search = new Subject<{ uniqueIdEncoded: string, startKey?: string }>();

  private _loading = false;

  private _getUniqueIdEncoded = this._functions.httpsCallable('callable-getUniqueIdEncoded');

  results: Observable<Payload<SegmentRef>[]>;

  private _segmentsPayloads: Payload<SegmentRef>[] = [];
  
  private _results = new BehaviorSubject<Payload<SegmentRef>[]>([]);

  uniqueId: string;

  constructor(
    private _global: GlobalService,
    private _segments: SegmentsService,
    private _functions: AngularFireFunctionsV1
  ) {
    this.results = this._results.asObservable();
   }

  ngOnDestroy() {
    this._global.loading = false;
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
    this._global.loading = value;
  }

  async search(uniqueId: string) {
    this.loading = true;
    this.uniqueId = uniqueId;
    const uniqueIdEncoded = await this._getUniqueIdEncoded({ value: uniqueId }).toPromise();
    this._segments.searchByUniqueId(uniqueId, uniqueIdEncoded)
      .subscribe(segmentRefs => {
        this.loading = false;
        this._segmentsPayloads = segmentRefs;
        this._results.next(this._segmentsPayloads);
      });
  }

  clear() {
    this._search.next(null);
  }

  async update(segment: Payload<SegmentRef>) {
    return this._segments
        .update(segment)
        .then(() => {
          const payload = this._segmentsPayloads.find(s => {
            return segment.key === s.key
          });

          payload.data = segment.data;
        });
  }

  async remove(segment: Payload<SegmentRef>) {
    return this._segments
        .remove(segment)
        .then(() => {
          const index = this._segmentsPayloads.findIndex(s => segment.key === s.key);

          if (index > -1)
            this._segmentsPayloads.splice(index, 1);
        });
  }

}
