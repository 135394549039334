export class Barcode {
  barcodeNumber: string;
  barcodeNumber_month: string;
  expirationDate: string;
  expiresAt: number;
  lineItemIdEN: string;
  lineItemIdES: string;
  lineItemIdFR: string;
  onlinePromoCode: string;
  onlinePromoCode_month: string;
  redeemed: boolean;
  segmentId: string;
  uniqueId: string;
  uniqueIdEncoded: string;
  uniqueIdEncodedURI: string;

  constructor(barcode) {
    const expiresAt = barcode.expirationDate
      ? new Date(barcode.expirationDate).getTime()
      : undefined;

    return {
      barcodeNumber: barcode.barcodeNumber,
      barcodeNumber_month: barcode.barcodeNumber_month,
      expirationDate: barcode.expirationDate,
      expiresAt,
      lineItemIdEN: barcode.lineItemIdEN,
      lineItemIdES: barcode.lineItemIdES,
      lineItemIdFR: barcode.lineItemIdFR,
      onlinePromoCode: barcode.onlinePromoCode,
      onlinePromoCode_month: barcode.onlinePromoCode_month,
      redeemed: barcode.redeemed,
      segmentId: barcode.segmentId,
      uniqueId: barcode.uniqueId,
      uniqueIdEncoded: barcode.uniqueIdEncoded,
      uniqueIdEncodedURI: barcode.uniqueIdEncodedURI
    };
  }
}
