import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PipesModule } from '../pipes/pipes.module';
import { MaterialModule } from '../material/material.module';
import { RouterModule } from '@angular/router';
import { PartnersComponent } from './partners/partners.component';
import { BarcodesComponent } from './barcodes/barcodes.component';
import { SegmentsComponent } from './segments/segments.component';
import { FormsModule } from '@angular/forms';
import { SegmentsByUniqueIdComponent } from './segments-by-unique-id/segments-by-unique-id.component';
import { BarcodesByUniqueIdComponent } from './barcodes-by-unique-id/barcodes-by-unique-id.component';
import { SegmentsEnrolledComponent } from './segments-enrolled/segments-enrolled.component';
import { BarcodesPurgeComponent } from './barcodes-purge/barcodes-purge.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../http-interceptors/auth.interceptor';
import { QueueComponent } from './queue/queue.component';
import { BarcodesRedeemedComponent } from './barcodes-redeemed/barcodes-redeemed.component';
import { SegmentsImportComponent } from './segments-import/segments-import.component';
import { BarcodesImportComponent } from './barcodes-import/barcodes-import.component';
import { SegmentsPurgeComponent } from './segments-purge/segments-purge.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { DynamicContentComponent } from './dynamic-content/dynamic-content.component';
import { DynamicContentDashboardComponent } from './dynamic-content-dashboard/dynamic-content-dashboard.component';
import { DynamicContentImportComponent } from './dynamic-content-import/dynamic-content-import.component';
import { AcqDashboardComponent } from './acq-dashboard/acq-dashboard.component';
import { UploadComponent } from './upload/upload.component';
import { GtmOptInSegmentsComponent } from './gtm-opt-in-segments/gtm-opt-in-segments.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    MaterialModule,
    PipesModule,
    RouterModule,
    FormsModule,
    HttpClientModule
  ],
  declarations: [
    LoginComponent,
    DashboardComponent,
    PartnersComponent,
    BarcodesComponent,
    SegmentsComponent,
    BarcodesByUniqueIdComponent,
    SegmentsByUniqueIdComponent,
    SegmentsEnrolledComponent,
    BarcodesPurgeComponent,
    QueueComponent,
    BarcodesRedeemedComponent,
    SegmentsImportComponent,
    BarcodesImportComponent,
    SegmentsPurgeComponent,
    ResetPasswordComponent,
    DynamicContentComponent,
    DynamicContentImportComponent,
    DynamicContentDashboardComponent,
    AcqDashboardComponent,
    GtmOptInSegmentsComponent,
    UploadComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class ContainersModule {}
