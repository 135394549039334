import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { SearchService } from '../../services/search.service';


@Component({
  selector: 'app-search-marketing-offers',
  templateUrl: './search-marketing-offers.component.html',
  styleUrls: ['./search-marketing-offers.component.scss']
})
export class SearchMarketingOffersComponent implements OnInit {

  searchedForId: string;
  dmarUniqueId: string;
  searchHasResults = false;
  encodeBeforeSearch = true;
  showSearchOptions = false;
  searchInProgress = false;
  searchErrorMsg = '';
  jsonSearchResponse: JSON;

  searchFormControl = new FormControl('', [
    Validators.nullValidator,
    Validators.minLength(16)
  ]);

  constructor(
              private searchService: SearchService
  ) { }


  ngOnInit(): void {
  }

  get searchOptionsText(): string {
    return this.showSearchOptions ? 'hide search options' : 'show search options';
  }
  toggleEncodeSearch() {
    this.encodeBeforeSearch = !this.encodeBeforeSearch;
  }
  get exampleUniqueIdForInput(): string {
    return this.encodeBeforeSearch ? '0000063707425500' : 'dmVjdHZmd3pxZXt0c3BiY39lY3V2ZXB%2Bc2N%2BdHVxZ2U%3D';
  }
  get id(): string {
    return this.searchFormControl.value;
  }

  toggleOptions() {
    this.showSearchOptions = !this.showSearchOptions;
  }

  async getMarketingOffers() {
    console.log(this.searchFormControl);
    if (this.searchFormControl.invalid || this.id === null || this.id === '') {
      this.searchErrorMsg = 'please enter a valid user ID';
      return;
    }
    this.searchHasResults = false;
    this.searchInProgress = true;
    this.searchedForId = this.id;
    this.dmarUniqueId = this.searchedForId;

    // check if we are encoding or not
    if (this.encodeBeforeSearch) {
      // hit the search service to encode
      const encodeResponse =  await this.searchService.encodeDMARuniqueId(this.dmarUniqueId);
      console.log('encodeResponse: ', encodeResponse);
      this.dmarUniqueId = encodeResponse;
    }

    // searchResults should be the data from SearchResponse
    this.jsonSearchResponse = await this.searchService.searchMarketingData(this.dmarUniqueId);
    console.log(Object.keys(this.jsonSearchResponse).length);

    this.searchHasResults = true;
    // clear the form
    this.searchFormControl.reset();
    this.searchInProgress = false;
  }
}
