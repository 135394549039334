import { REGION } from '@angular/fire/functions';
import { AuthInterceptor } from '../http-interceptors/auth.interceptor';
import { AuthService } from './auth.service';
import { Authv2Service } from './authv2.service';
import { BarcodesService } from './barcodes.service';
import { DynamicContentService } from './dynamic-content.service';
import { GlobalService } from './global.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { QueueService } from './queue.service';
import { SegmentsService } from './segments.service';
import { FirebaseModule } from '../firebase/firebase.module';
import { SearchService } from './search.service';
import { ACQService } from './acq.service';
import { UploadService } from './upload.service'

@NgModule({
  imports: [
    FirebaseModule
  ],
  providers: [
    AuthService,
    Authv2Service,
    BarcodesService,
    DynamicContentService,
    GlobalService,
    QueueService,
    SegmentsService,
    SearchService,
    ACQService,
    UploadService,
    { provide: REGION, useValue: 'us-central1' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class ServicesModule { }
