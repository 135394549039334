import { AbstractControl } from '@angular/forms';

export function ValidateBarcodeExpirationDate(control: AbstractControl) {
  // Validates date format MM-DD-YY
  if (!control.value.match(/(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])-([0-9][0-9])/)) {
    return { expirationDate: true  };
  }

  return null;
}
