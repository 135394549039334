import { Injectable } from '@angular/core';
import { AngularFireAction } from '@angular/fire/database';
import { GlobalService } from './global.service';
import { DataSnapshot } from '@firebase/database-types';
import { Observable } from 'rxjs';
import { throttleTime, tap } from 'rxjs/operators';
import { Barcode } from '../models/barcode.model';
import { BarcodeRef, Payload, BarcodesIndexOn, ListFile } from '../interfaces';
import { AngularFireDatabaseV1 } from '../firebase/firebasev1.module';
import { DatabaseReference } from '@angular/fire/database/interfaces';

@Injectable()
export class BarcodesService {
  constructor(
    private _global: GlobalService,
    private _rtdb: AngularFireDatabaseV1
  ) { }

  private get _barcodesRef(): DatabaseReference {
    return this._global.partnerRootRef.child('/barcodes');
  }

  listFiles: ListFile[];

  search(searchBy: BarcodesIndexOn, value: any, many = 60, startKey?: string): Observable<AngularFireAction<DataSnapshot>[]> {
    return this._rtdb
      .list(this._barcodesRef, ref => {
        return ref
          .orderByChild(searchBy)
          .limitToFirst(many + 1)
          .startAt(value, startKey)
          .endAt(value);
      })
      .snapshotChanges()
      .pipe(throttleTime(2000, undefined, { leading: true, trailing: true }))
      .pipe(tap(result => {
        if (result.length > many) {
          const next = result.pop();
          result['next'] = { key: next.key, value };
        }
      }));

  }

  // searchByExpiredDate(expiredAt: number, many = 60, startAt = 0, startKey?: string): Observable<AngularFireAction<DataSnapshot>[]> {
  //   return this._rtdb
  //     .list(this._barcodesRef, ref => {
  //       return ref
  //         .orderByChild(BarcodesIndexOn.expiresAt)
  //         .limitToFirst(many + 1)
  //         .startAt(startAt, startKey)
  //         .endAt(expiredAt);
  //     })
  //     .snapshotChanges()
  //     .pipe(throttleTime(2000, undefined, { leading: true, trailing: true }))
  //     .pipe(tap(result => {
  //       if (result.length > many) {
  //         const next = result.pop();
  //         result['next'] = { key: next.key, value: next.payload.val().expiresAt };
  //       }
  //     }));
  // }

  async remove(payload: Payload<BarcodeRef>) {
    if (!payload.key) {
      throw Error('[barcode.remove()] Missing ref key');
    }

    return this._rtdb.object(this._barcodesRef.child(payload.key)).remove();
  }

  async update(payload: Payload<BarcodeRef>) {
    if (!payload.key) {
      throw Error('[barcode.update()] Missing ref key');
    }

    this._global.loading = true;

    const barcode = new Barcode(payload.data);

    const updateRefs = {
      [`/barcodes/${payload.key}/barcodeNumber_month`]: barcode.barcodeNumber_month,
      [`/barcodes/${payload.key}/barcodeNumber`]: barcode.barcodeNumber,
      [`/barcodes/${payload.key}/expirationDate`]: barcode.expirationDate,
      [`/barcodes/${payload.key}/expiresAt`]: barcode.expiresAt,
      [`/barcodes/${payload.key}/lineItemIdEN`]: barcode.lineItemIdEN,
      [`/barcodes/${payload.key}/lineItemIdES`]: barcode.lineItemIdES,
      [`/barcodes/${payload.key}/lineItemIdFR`]: barcode.lineItemIdFR,
      [`/barcodes/${payload.key}/onlinePromoCode_month`]: barcode.onlinePromoCode_month,
      [`/barcodes/${payload.key}/onlinePromoCode`]: barcode.onlinePromoCode,
      [`/barcodes/${payload.key}/redeemed`]: barcode.redeemed,
      [`/barcodes/${payload.key}/segmentId`]: barcode.segmentId,
      [`/barcodes/${payload.key}/uniqueId`]: barcode.uniqueId,
      [`/barcodes/${payload.key}/uniqueIdEncoded`]: barcode.uniqueIdEncoded,
      [`/barcodes/${payload.key}/uniqueIdEncodedURI`]: barcode.uniqueIdEncodedURI
    };

    const updates = Object.keys(updateRefs).reduce((h, k) => {
      return (typeof updateRefs[k] === 'undefined') ? { ...h } : { ...h, [k]: updateRefs[k] };
    }, {});

    return this._rtdb
      .object(this._global.partnerRootRef)
      .update(updates)
      .catch(error => {
        this._global.error = error;
      })
      .then(response => {
        this._global.loading = false;
        return response;
      });
  }
}
