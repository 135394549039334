import { Component, OnInit } from '@angular/core';
import { OfferType } from '../../models/enums';

@Component({
  selector: 'app-gtm-opt-in',
  templateUrl: './gtm-opt-in.component.html',
  styleUrls: ['./gtm-opt-in.component.css']
})
export class GtmOptInComponent implements OnInit {

  SPECIAL_OFFERS = OfferType.SPECIAL_OFFER;

  constructor() { }

  ngOnInit(): void {
  }

}
