import { Component, OnInit } from '@angular/core';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { QueueType } from '../../interfaces';
import { QueueService } from '../../services/queue.service';
import { GlobalService } from '../../services/global.service';
import { AngularFireStorageV1 } from '../../firebase/firebasev1.module';

@Component({
  selector: 'app-barcodes-import',
  templateUrl: './barcodes-import.component.html',
  styleUrls: ['./barcodes-import.component.scss']
})
export class BarcodesImportComponent implements OnInit {

  private _expirationDateData: Date;

  file: File = null;

  loading = false;

  segments: string[] = [];

  step = 0;

  constructor(
    private _queue: QueueService,
    private _global: GlobalService,
    private _storage: AngularFireStorageV1,
    private _dialog: MatDialog
  ) { }

  ngOnInit() { }

  get siteId(): string {
    return this._global.siteId;
  }

  get expiresAt(): number {
    return this.expirationDateData ? this.expirationDateData.getTime() : null;
  }

  get expirationDateData(): Date {
    return this._expirationDateData;
  }

  set expirationDateData(value: Date) {
    this._expirationDateData = value ? new Date(value.setHours(23, 59, 59, 59)) : null;
  }

  get expirationDate(): string {
    return this.expirationDateData ?
      this.expirationDateData.toLocaleString('en-us', { year: '2-digit', month: '2-digit', day: '2-digit' }).replace(/\//g, '-')
      : null;
  }

  private async _getSegments(data: string): Promise<string[]> {
    const rows = data.trim().split('\n');
    const keys = rows.shift().trim().split(',');
    const segmentIdIndex = keys.indexOf('AX_OFFER');
    const segmentsHash = {};

    rows.forEach(r => {
      if (!r) {
        return;
      }
      const segmentId = r.trim().split(',')[segmentIdIndex];
      segmentsHash[segmentId] = true;
    });

    return Object.keys(segmentsHash).sort();
  }

  attachCSVFile(file: File) {
    if (!file) {
      return this.triggerCancel();
    }

    this.loading = true;
    this.file = file;

    const reader = new FileReader();

    reader.onload = async (_file: any) => {
      this.segments = await this._getSegments(_file.target.result);
      this.loading = false;
    };

    reader.readAsText(file);
  }

  triggerCancel() {
    this.file = null;
    this.segments = [];
    this.step = 0;
  }

  triggerSubmit(file, lineItems) {
    const filePath = `csv/${file.name}`;

    const uploadTask = this._storage.upload(filePath, file);
    uploadTask.pause();

    this._dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Import Barcode Offers',
        body: `Are you sure you want to import barcode offers from <em>${file.name}</em> to <em>${this._global.siteId}</em>?`,
        accept: async () => {
          uploadTask.resume();
          const _file = await uploadTask;
          return this._queue.addToPendingJobs(QueueType.barcodeNew, {
            filePath: _file.metadata.fullPath,
            lineItems
          }).catch((async error => {
            await _file.ref.delete();
            throw new Error(error);
          }));
        },
        cancel: async () => uploadTask.cancel(),
        loading: uploadTask.percentageChanges()
      }
    });

  }

}
