import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.css']
})
export class ResetPasswordFormComponent {

  @Output() handleSubmit = new EventEmitter<string>();

  @Input() error: string;

  @Input() success: boolean;

  onSubmit(email: string) {
    this.handleSubmit.emit(email);
  }

}
