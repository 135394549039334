import { Component, OnInit, OnDestroy } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { Results, QueueType } from '../../interfaces';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { QueueService } from '../../services/queue.service';
import { AngularFireStorageV1 } from '../../firebase/firebasev1.module';

@Component({
  selector: 'app-barcodes-redeemed',
  templateUrl: './barcodes-redeemed.component.html',
  styleUrls: ['./barcodes-redeemed.component.css']
})
export class BarcodesRedeemedComponent implements OnInit, OnDestroy {

  private _loading = false;

  listFileCSV: File;

  results: Results = [];

  constructor(
    private _global: GlobalService,
    private _dialog: MatDialog,
    private _storage: AngularFireStorageV1,
    private _queue: QueueService
  ) { }

  ngOnInit() { }

  ngOnDestroy() {
    this._global.loading = false;
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
    this._global.loading = value;
  }

  get siteId(): string {
    return this._global.siteId;
  }

  redeem(file: File) {
    const filePath = `csv/${file.name}`;

    const uploadTask = this._storage.upload(filePath, file);
    uploadTask.pause();

    this._dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Redeem Barcode Offers',
        body: `Are you sure you want to redeem barcode offers from <em>${file.name}</em> to <em>${this._global.siteId}</em>.`,
        accept: async () => {
          uploadTask.resume();
          const _file = await uploadTask;
          return this._queue.addToPendingJobs(QueueType.barcodeRedeem, {
            filePath: _file.metadata.fullPath
          }).catch((async error => {
            await _file.ref.delete();
            throw new Error(error);
          }));
        },
        cancel: async () => uploadTask.cancel(),
        loading: uploadTask.percentageChanges()
      }
    });
  }

}
