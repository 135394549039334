import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { QueueService } from '../../services/queue.service';
import { Results } from '../../interfaces';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { takeWhile, switchMap } from 'rxjs/operators';
import { OfferType } from '../../models/enums';

@Component({
  selector: 'app-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss']
})
export class QueueComponent implements OnInit, OnDestroy {

  private _alive = false;
  private _archived = new Subject<{ startAt?: number, startKey?: string }>();
  private _pending = new Subject<{ startAt?: number, startKey?: string }>();

  archived: Results = [];
  pending: Results = [];
  SPECIAL_OFFERS = OfferType.SPECIAL_OFFER;

  constructor(
    private _global: GlobalService,
    private _queue: QueueService,
    private _dialog: MatDialog
  ) {
    this._global.title = 'Queue';

    this._pending.pipe(
      switchMap(({ startAt, startKey }) => {
        this._global.loading = true;
        return this._queue.pending(10, startAt, startKey)
          .pipe(takeWhile(() => this._alive));
      }))
      .subscribe(results => {
        this._global.loading = false;
        this.pending = results;
      });

    this._archived
      .pipe(takeWhile(() => this._alive))
      .pipe(switchMap(({ startAt, startKey }) => {
        this._global.loading = true;
        return this._queue.archived(10, startAt, startKey)
          .pipe(takeWhile(() => this._alive));
      }))
      .subscribe(results => {
        this._global.loading = false;
        this.archived = results;
      });
  }

  ngOnInit() {
    this._alive = true;
    setTimeout(() => {
      this._pending.next({});
      this._archived.next({});
    });
  }

  ngOnDestroy() {
    this._global.loading = false;
    this._alive = false;
    this._archived.unsubscribe();
    this._pending.unsubscribe();
  }

  archive(key: string) {
    return this._queue.archive(key);
  }

  cancel(key: string) {
    this._dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Cancel Job',
        body: `Are you sure you would like to cancel this queue job.`,
        accept: async () => this._queue.cancel(key)
      }
    });
  }

  removeArchived(key: string) {
    this._dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Remove Archived Job',
        body: `Are you sure you would like to remove this archived queue job.`,
        accept: async () => this._queue.removeArchived(key)
      }
    });
  }

  goToNextPendingPage(startAt: number, startKey: string) {
    this._pending.next({ startAt, startKey });
  }

  goToNextArchivedPage(startAt: number, startKey: string) {
    this._archived.next({ startAt, startKey });
  }


}
